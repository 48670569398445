import {FC} from 'react';
import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {useTheme, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';
import {defaultApexOptions} from './apexOptions';

type Props = GenericBoxProps & {
  value: number;
  color?: string;
  isNotBoxed?: boolean;
  printValue?: boolean;
  height?: string | number;
  hollowSize?: string;
};

export const ComponentRadialbar: FC<Props> = ({value, color = 'primary', isNotBoxed = false, printValue = true, height = 330, hollowSize = '80%', ...genericBoxProps}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  if (value === -1) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  const options: ApexOptions = {
    chart: {
      ...defaultApexOptions.chart,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: hollowSize,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: printValue,
            color: theme.colors.coolGray[700],
            fontSize: theme.fontSizes['4xl'].toString(),
            fontWeight: theme.fontWeights.semibold,
          },
        },
      },
    },
    fill: {
      colors: [theme.colors.primary[600]],
    },
    stroke: {
      lineCap: 'round',
    },
  };

  if (!value && value !== 0) {
    return null;
  }

  const series = [value];

  const render = <ReactApexChart options={options} series={series} type="radialBar" height={height} />;

  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};
