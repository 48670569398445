import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {FC, useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {COOKIES_TYPES, CookiesService, loggedUser, useAuthService} from '@bri/shared-components';
import jwt from 'jwt-decode';
import {USER_ACTIONS} from '@bri/circularpass-adalmo-core';

type Props = {
  action?: USER_ACTIONS;
  redirectPath?: string;
  children?: any;
};

// https://www.robinwieruch.de/react-router-private-routes/
// https://www.youtube.com/watch?v=O4FIR4ug7y8

const ProtectedRoute: FC<Props> = ({action, redirectPath = '/login', children}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useRecoilState(loggedUser);
  const [userActions, setUserActions] = useState<string[]>([]);
  const authService = useAuthService();

  useEffect(() => {
    const fetchData = async () => {
      const token = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
      if (token) {
        const token_decoded = jwt(token); // decode your token here
        setUserActions((token_decoded as any).aud.split(' ').map((item: string) => item.replace('ACTION:', '')));

        authService
          .me()
          .response(resp => {
            setUser(resp.user);
          })
          .error(err => {
            setUser(null);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };
    fetchData().catch(error => {
      setLoading(false);
      console.error(error);
    });
  }, []);

  if (loading) return null;

  if (!user || (action && userActions && !userActions.includes(action))) {
    return <Navigate to={redirectPath} replace state={{from: location}} />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
