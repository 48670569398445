import React from 'react';
import {Login} from '@bri/shared-components';
import {Flex, HStack, Link as NBLink, View} from 'native-base';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CurrentTheme} from '../theme';

const LoginPage = () => {
  const {t} = useTranslation();

  return (
    <div className="outlet" style={{zIndex: -1, minHeight: '100vh', marginTop: 0, marginLeft: 0}}>
      <div
        style={{
          width: '100%',
          backgroundImage: `url(${CurrentTheme.login.backgroundUrl})`,
          backgroundSize: 'cover',
          position: 'absolute',
          right: 0,
        }}>
        <div
          style={{
            width: '100%',
            background: 'rgba(2,132,199,0.8)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Flex h={window.innerHeight} style={{justifyContent: 'center', alignItems: 'center'}}>
            <Login logo={CurrentTheme.login.logo} />
            <HStack mb={4} mx={4} position="absolute" bottom={4}>
              <View px={2}>
                <Link to="/cookies-config" style={{textDecoration: 'none', textAlign: 'center'}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Preferencias de cookies')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/cookies" style={{textDecoration: 'none', textAlign: 'center'}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Política de cookies')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/termsAndConditions" style={{textDecoration: 'none', textAlign: 'center'}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Términos y condiciones')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/privacyPolicy" style={{textDecoration: 'none', textAlign: 'center'}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Política privacidad')}
                  </NBLink>
                </Link>
              </View>
            </HStack>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
