import {FC} from 'react';
import {Stack, Text, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox} from '../GenericBox';
import {ComponentRadialbar} from './ComponentRadialbar';

type Props = {
  title: string;
  percent: number;
  description: string;
};

const ComponentPieSidebarData: FC<Props> = ({title, percent, description}) => {
  const {t} = useTranslation();

  if (percent === undefined) {
    return (
      <GenericBox title={title}>
        <Text>{t(`No se ha encontrado ${title}`)}</Text>
      </GenericBox>
    );
  }

  return (
    <GenericBox title={title}>
      <Stack direction={{base: 'column', md: 'row'}} space="lg" flexWrap="wrap">
        <View w={32} alignSelf="center">
          <ComponentRadialbar title={title} value={percent} printValue={false} height={150} hollowSize="60%" isNotBoxed />
        </View>
        <VStack alignSelf="center" flex={1}>
          <Text color="coolGray.700" fontSize="3xl" fontWeight="semibold">
            {Math.round(percent)}%
          </Text>
          <Text color="coolGray.700">{description}</Text>
        </VStack>
      </Stack>
    </GenericBox>
  );
};

export default ComponentPieSidebarData;
