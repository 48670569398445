import {FC} from 'react';
import {HStack, Icon, VStack, View, useBreakpointValue, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericModal, Props as BaseProps} from './GenericModal';
import TableBox from './TableBox';
import AdalmoIcon from '../config/adalmo-font.js';
import ComponentValuePercent from './graphs/ComponentValuePercent';
import ComponentValue from './graphs/ComponentValue';
import {Map} from '../components/MapFilter';

type Props = Pick<BaseProps, 'isOpen' | 'onClose'> & {
  // id?: string;
  data: any;
  title: string;
};

const SummaryDistributionTraceabilityModal: FC<Props> = ({data, isOpen, onClose, title}) => {
  const {t} = useTranslation();
  const isModalVertical: boolean = useBreakpointValue({base: true, lg: false});

  if (!data) {
    return null;
  }

  const tableBoxData = {
    title: t('Caracteristicas'),
    dataTable: [
      {label: t('Descripción'), value: data.description},
      {label: t('Codigo LER'), value: data.ierCode},
      {label: t('Caracteristicas peligrosidad'), value: data.dangerProperties},
      {label: t('Tratamiento'), value: data.treatment},
      {label: t('Peso'), value: data.weight},
      {label: t('Origen'), value: data.origin.name},
      {label: t('Destino'), value: data.destiny.name},
      {label: t('Fecha'), value: data.eventTime},
    ],
  };

  const tableBoxData2 = {
    title: t('Trazabilidad geográfica'),
    dataTable: [
      {label: t('Kilómetros'), value: data.distance},
      {label: t('Tipo transporte'), value: data.transport.type},
      {label: t('Transportista'), value: data.transport.shipper},
      {label: t('Vehiculo'), value: data.transport.vehicle.id},
      {label: t('Peso vehiculo'), value: data.transport.vehicle.weight},
    ],
  };

  const arrowsMap = [
    {
      filter: data.transport.type,
      route: data.route.map((a: any) => ({lat: a.lat, lng: a.lng})),
    },
  ];

  const locationsMap = [
    {lat: data.route[0].lat, lng: data.route[0].lng, image: data.origin.image},
    {lat: data.route[data.route.length - 1].lat, lng: data.route[data.route.length - 1].lng, image: data.destiny.image},
  ];

  return (
    <GenericModal
      title={
        <HStack space={5}>
          <VStack>
            <Text color="coolGray.700" fontWeight="bold">
              {title}
            </Text>
          </VStack>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      heightPercent={isModalVertical ? 0.9 : 0.75}
      widthPercent={isModalVertical ? 0.95 : 0.75}
      padding={isModalVertical ? {sides: '30px', top: '20px', bottom: '10px'} : {sides: '30px', top: '30px', bottom: '5px'}}
      minHeight="0px">
      <View py={5} pb={10}>
        <HStack space={2}>
          <VStack space={2} width="50%">
            <View>
              <TableBox title={tableBoxData.title} data={tableBoxData.dataTable} />
            </View>
            <View>
              <TableBox title={tableBoxData2.title} data={tableBoxData2.dataTable} />
            </View>
            <ComponentValuePercent
              title={t('Ahorro CO2 por compra circular')!}
              icon={<Icon as={AdalmoIcon} name="co2_leaf" size={5} color="primary.600" />}
              data={{value: data.co2savingsByCircularPurchase.value, units: data.co2savingsByCircularPurchase.units}}
              percentages={data.co2savingsByCircularPurchase.breakdown.map((breakdown: any, index: number) => ({
                ...breakdown,
                colorScheme: ['primary', 'danger'][index],
              }))}
            />
          </VStack>
          <VStack space={2} width="50%" height={100}>
            <View>
              <Map
                title={t('Trazabilidad del Producto')}
                description={t('Recorrido del producto en sus distintas fases de diseño y producción')!}
                icon={<Icon as={AdalmoIcon} name="compass" size={5} color="primary.600" />}
                showFilter
                locations={locationsMap}
                arrows={arrowsMap}
              />
            </View>
            <View>
              <ComponentValue
                title={t('Huella de carbono')!}
                icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />}
                data={{value: data.co2Generated, units: 't CO2'}}
              />
            </View>
          </VStack>
        </HStack>
      </View>
    </GenericModal>
  );
};

export default SummaryDistributionTraceabilityModal;
