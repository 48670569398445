import {useRouteError} from 'react-router-dom';
import {PageLayout} from '../components/PageLayout';

type Props = {
  code?: number;
  message?: string;
};
export default function ErrorPage({code, message}: Props) {
  const error: any = useRouteError();
  console.error(error);

  return (
    <PageLayout>
      <h1>Oops! {code || ''}</h1>
      <p>{message || 'Sorry, an unexpected error has occurred.'}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </PageLayout>
  );
}
