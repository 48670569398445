import {FC, useEffect, useLayoutEffect, useState} from 'react';
import {useTheme} from 'native-base';
import {sankey, sankeyJustify, sankeyLinkHorizontal} from 'd3-sankey';
import {Defs, LinearGradient, Path, Rect, Stop, Svg} from 'react-native-svg';
import {maxDecimals} from '../../utils/maxDecimals';

const MARGIN_Y = 25;
const MARGIN_X = 5;
const MIN_WIDTH = 0;
const EXTRA_HEIGHT_MARGIN = 35;

type Props = {
  initialWidthFraction: number;
  height: number;
  data: any;
  color?: string;
  flow?: any;
};

export const ComponentSankey: FC<Props> = ({initialWidthFraction, height, data, flow, color = 'primary'}) => {
  const calculateWidth = () => window.innerWidth * initialWidthFraction;
  const [width, setWidth] = useState(calculateWidth());

  const handleResize = () => {
    setWidth(calculateWidth());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  const theme = useTheme();
  const {colors} = theme;
  const colorPalette = colors[color as keyof typeof colors];
  const hexValues = Object.values(colorPalette);

  let colorGraphic: any[] = [];
  if (flow === 'Inflow') {
    colorGraphic = [
      {number: 0, color: hexValues[6], type: 'plain'},
      {number: 1, color: hexValues[2], type: 'plain'},
    ];
  } else if (flow === 'Outflow') {
    colorGraphic = [
      {number: 0, color: hexValues[6], type: 'plain'},
      {number: 1, color: hexValues[2], type: 'plain'},
      {number: 2, color: hexValues[4], type: 'gradient1'}, //* -> RECUPERADO + RECICLADO
      {number: 3, color: hexValues[4], type: 'gradient2'},
      {number: 4, color: hexValues[6], type: 'plain'},
      {number: 5, color: hexValues[2], type: 'plain'},
    ];
  }

  const sankeyGenerator = sankey()
    .nodeWidth(4) // width of the node in pixels
    .nodePadding(13) // space between nodes
    .extent([
      [MARGIN_X, MARGIN_Y],
      [width - MARGIN_X, height - MARGIN_Y],
    ])
    .nodeId(node => (node as any).id) // ? Undefined id, fails if its not as any
    .nodeAlign(sankeyJustify);

  const {nodes, links} = sankeyGenerator(data);

  const adjustedHeightLinks: any[] = [];
  const isMinHeight: any[] = [];
  const isMinHeightPostNode: any[] = [];
  const resizeLinks: any[] = [];

  const allNodes = getAllNodes(nodes, isMinHeight, isMinHeightPostNode, adjustedHeightLinks, sankeyGenerator);
  const allLinks = getAllLinks(nodes, links, resizeLinks, adjustedHeightLinks, colorGraphic);
  const allLabels = getAllLabels(nodes, width, theme, height);

  if (!data) {
    return null;
  }

  return (
    <Svg width={width} height={height + EXTRA_HEIGHT_MARGIN}>
      <Defs>
        <LinearGradient id="gradient1" x1="0%" x2="100%" y1="0%" y2="0%" gradientUnits="objectBoundingBox">
          <Stop offset="0%" stopColor={hexValues[6]} />
          <Stop offset="50%" stopColor={hexValues[4]} />
          <Stop offset="100%" stopColor={hexValues[6]} />
        </LinearGradient>
        <LinearGradient id="gradient2" x1="0%" x2="100%" y1="0%" y2="0%" gradientUnits="objectBoundingBox">
          <Stop offset="0" stopColor={hexValues[6]} />
          <Stop offset="1" stopColor={hexValues[2]} />
        </LinearGradient>
      </Defs>
      {allNodes}
      {allLinks}
      {allLabels}
    </Svg>
  );
};

const getAllLinks = (nodes: any, links: any, resizeLinks: any[], adjustedHeightLinks: any[], colorGraphic: any[]) =>
  links.map((link: any, i: number) => {
    const linkGenerator = sankeyLinkHorizontal();
    let adjustedWidth = link.width! < MIN_WIDTH && (link as any).source.id !== '3' ? MIN_WIDTH : link.width;
    if (link.width! < MIN_WIDTH && (link as any).source.id !== '3') {
      const difference = (adjustedWidth! - link.width!) / 2;
      /* eslint no-param-reassign: "error" */
      link.y0! += difference;
      /* eslint no-param-reassign: "error" */
      link.y1! += difference;
      if ((link as any).target.sourceLinks[0]) {
        resizeLinks.push({source: (link as any).target.sourceLinks[0], difference});
      }
    }
    const searchLink = resizeLinks.find((l: any) => link.index === l.source.index);
    if (searchLink) {
      link.y0! += searchLink.difference;
      link.y1! += searchLink.difference;
      const node = adjustedHeightLinks.find((a: any) => a.index === link.index);
      if (node) adjustedWidth = node.height;
    }
    const path = linkGenerator(link);
    if (path != undefined) {
      const selectColor = colorGraphic.find(cg => cg.number === i);
      if (selectColor.type !== 'plain') {
        return <Path key={i} d={path} stroke={`url(#${selectColor.type})`} fill="none" strokeOpacity={0.9} strokeWidth={adjustedWidth} />;
      }
      return <Path key={i} d={path} stroke={selectColor.color} fill="none" strokeOpacity={0.9} strokeWidth={adjustedWidth} />;
    }
  });

const getAllLabels = (nodes: any, width: number, theme: any, height: number) =>
  nodes.map((node: any, i: number) => {
    if (node.x0 != undefined && node.x1 != undefined && node.y0 != undefined && node.y1 != undefined) {
      if ((node as any).flow === 'Inflow') {
        if (node.index === 2) {
          return (
            // TODO text y & x individualment centrat en els 2 axis segons diseny
            <text
              key={i}
              x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6}
              y={(node.y1 + node.y0) / 15}
              dy="0.35rem"
              textAnchor={node.x0 < width / 2 ? 'start' : 'end'}
              style={{fontFamily: 'Avenir-Regular'}}
              fontSize={12}>
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {(node as any).category}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.3em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            </text>
          );
        }
        if (node.index === 3) {
          return (
            <text
              key={i}
              x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6}
              y={node.y1 <= height / 2 ? 17 : (node.y1 + node.y0) / 2}
              dy="0.35rem"
              textAnchor={node.x0 < width / 2 ? 'start' : 'end'}
              style={{fontFamily: 'Avenir-Regular'}}
              fontSize={12}>
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).category}
                </tspan>
              </>
            </text>
          );
        }
      } else if ((node as any).flow === 'Outflow') {
        let variableY = 0;
        let variableX = 0;
        let children = null;

        switch (node.index) {
          case 0:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = (node.y1 + node.y0) / 12;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {(node as any).category}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.3em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            );
            break;
          case 1:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = node.y1 <= height / 2 ? 17 : (node.y1 + node.y0) / 2;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).category}
                </tspan>
              </>
            );
            break;
          case 3:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = (node.y1 + node.y0) / 8;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {(node as any).category}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.3em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            );
            break;
          case 4:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = node.y1 / 1.05;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}}>{(node as any).category} </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}}>{maxDecimals((node as any).value, 2)}%</tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            );
            break;
          case 5:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = (node.y1 + node.y0) / 8;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="0em">
                  {(node as any).category}
                </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}} x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.3em">
                  {maxDecimals((node as any).value, 2)}%
                </tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            );
            break;
          case 6:
            variableX = node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6;
            variableY = node.y1 / 1.05;
            children = (
              <>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.sm}}>{(node as any).category} </tspan>
                <tspan style={{fontWeight: 'bold', fontSize: theme.fontSizes.xl}}>{maxDecimals((node as any).value, 2)}%</tspan>
                <tspan x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} dy="1.5em">
                  {(node as any).size}
                </tspan>
              </>
            );
            break;
        }
        return (
          <text key={i} x={variableX} y={variableY} style={{fontFamily: 'Avenir-Regular'}} dy="0.35rem" textAnchor={node.x0 < width / 2 ? 'start' : 'end'} fontSize={12}>
            {children}
          </text>
        );
      }
    }
  });

const getAllNodes = (nodes: any, isMinHeight: any[], isMinHeightPostNode: any[], adjustedHeightLinks: any[], sankeyGenerator: any) =>
  nodes.map((node: any) => {
    if (node.y1 != undefined && node.y0 != undefined) {
      let adjustedHeight;
      if (node.y1 - node.y0 < MIN_WIDTH && (node as any).category !== 'Lost potential') {
        adjustedHeight = node.y1 - node.y0 < MIN_WIDTH ? MIN_WIDTH : node.y1 - node.y0;
        if ((node.sourceLinks as any)![0]) isMinHeight.push({target: (node.sourceLinks as any)![0].target, source: (node.sourceLinks as any)![0].source});
      } else adjustedHeight = node.y1 - node.y0;

      const searchNode = isMinHeight.find((n: any) => node.index === n.target.index);
      if (searchNode) {
        if (searchNode.source.flow != 'Inflow') {
          adjustedHeight += MIN_WIDTH - (searchNode.source.y1 - searchNode.source.y0);
          isMinHeightPostNode.push({source: searchNode.target.sourceLinks[0].target, resize: searchNode.source.y1 - searchNode.source.y0});
        }
      }
      const searchNodePost = isMinHeightPostNode.find((n: any) => node.index === n.source.index);
      if (searchNodePost) {
        adjustedHeight = searchNodePost.source.y1 - searchNodePost.source.y0 - searchNodePost.resize + MIN_WIDTH;
        adjustedHeightLinks.push({index: searchNodePost.source.targetLinks[0].index, height: adjustedHeight});
      }
      return (
        // TODO wrap quan es posa en vista mobil
        <g key={node.index}>
          <Rect height={adjustedHeight} width={sankeyGenerator.nodeWidth()} x={node.x0} y={node.y0} stroke="transparent" fill="black" fillOpacity={0.8} rx={0.9} />
        </g>
      );
    }
  });
