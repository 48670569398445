import {FC} from 'react';
import {Text, useTheme} from 'native-base';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';

export type Props = GenericBoxProps & {
  data: {label: string; value: number}[];
  isNotBoxed?: boolean;
  verticalLegend?: boolean;
};

export const ComponentBarChart: FC<Props> = ({data, isNotBoxed, verticalLegend = false, ...genericBoxProps}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {colors} = theme;
  const color = 'primary';
  const colorPalette = colors[color as keyof typeof colors];
  const hexValues = Object.values(colorPalette);
  const startIndex = data.length < 4 ? 2 : 0;
  const colorsGraphic = hexValues.slice(startIndex, hexValues.length);

  if (!data || !data.length) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  const series = data.map((e, index) => {
    const d: number[] = [];
    d.push(e.value);
    return {
      name: e.label as string,
      data: d,
    };
  });

  const chartData: ApexOptions = {
    chart: {
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 450,
        options: {
          chart: {
            width: '100%',
            height: verticalLegend ? '100%' : 150,
          },
        },
      },
      {
        breakpoint: 590,
        options: {
          chart: {
            width: '100%',
            height: verticalLegend ? '100%' : 130,
          },
        },
      },
      {
        breakpoint: 770,
        options: {
          chart: {
            width: '100%',
            height: verticalLegend ? '100%' : 110,
          },
        },
      },
      {
        breakpoint: 1401,
        options: {
          chart: {
            width: '100%',
            height: verticalLegend ? '100%' : 130,
          },
        },
      },
      {
        breakpoint: 1921,
        options: {
          chart: {
            width: '100%',
            height: verticalLegend ? '100%' : 110,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        barHeight: '100',
        horizontal: true,
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      itemMargin: {
        horizontal: 20,
        vertical: 2,
      },
      position: 'bottom',
      horizontalAlign: verticalLegend ? 'left' : 'center',
      formatter(label: string, options: any) {
        return `<span style="margin-right: 10px">${label}</span> <span style="margin-left: 5px"><b>${options.w.globals.series[options.seriesIndex]}%</b></span>`;
      },
      markers: {
        width: 12,
        height: 12,
        radius: 6,
        offsetX: -3,
        offsetY: 0,
      },
    },
    fill: {
      type: 'solid',
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: colorsGraphic,
  };

  if (!data || !data.length) {
    return null;
  }

  const render = <Chart options={chartData} series={series} type="bar" />;

  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};

export default ComponentBarChart;
