import {FC} from 'react';
import {HStack, Stack, Text, useTheme, View} from 'native-base';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';
import {ComponentSankey} from './ComponentSankey';

type Props = GenericBoxProps & {
  width: number;
  height: number;
  data: any;
  isNotBoxed?: boolean;
  dataFlow: string[];
  color?: string;
};

export const ComponentDataSankey: FC<Props> = ({data, width, height, dataFlow, color = 'primary', isNotBoxed = false, ...genericBoxProps}) => {
  const theme = useTheme();
  const {colors} = theme;
  const colorPalette = colors[color as keyof typeof colors];
  const hexValues = Object.values(colorPalette);
  const colorGraphic = hexValues.slice(3, hexValues.length);

  if (!data) {
    return null;
  }

  const render = (
    <View>
      <Stack>
        <ComponentSankey data={data} flow={data.nodes[0].flow} initialWidthFraction={width} height={height} />
        <Stack direction="row" space={10} alignItems="space-between">
          {dataFlow.map((obj, index) => (
            <HStack key={index} alignItems="stetch">
              <HStack alignItems="center" justifyContent="center" space={3}>
                <View rounded="lg" style={{width: 10, height: 10, backgroundColor: colorGraphic[index + index]}} />
                <Text fontSize="md">{obj}</Text>
              </HStack>
            </HStack>
          ))}
        </Stack>
      </Stack>
    </View>
  );
  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};
