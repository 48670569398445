import {Text, useTheme} from 'native-base';
import {FC} from 'react';
import Chart from 'react-google-charts';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';

type Country = Record<string, number>; // Spain: 2,Canada:2 ...

type Props = GenericBoxProps & {
  data: Country[];
  height?: string | number;
  isBoxed?: boolean;
  colors?: string[];
  showLegend?: boolean;
};

const ComponentGeochartHeatmap: FC<Props> = ({data, height = '400px', isBoxed = true, colors = undefined, showLegend = false, ...genericBoxProps}) => {
  const theme = useTheme();

  const chartColors = colors || ([theme.colors.primary[100], theme.colors.primary[500]] as string[]);

  const {t} = useTranslation();

  if (!data || !data.length) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  const chart = (
    <Chart chartType="GeoChart" data={[['Country', 'Value'], ...data]} options={{colors: chartColors, legend: showLegend ? undefined : 'none'}} width="100%"
height={height} />
  );

  return isBoxed ? <GenericBox {...genericBoxProps}>{chart}</GenericBox> : chart;
};

export default ComponentGeochartHeatmap;
