import React, {useEffect, useState} from 'react';
import {Box, HStack, Pressable, useTheme, Text, Menu} from 'native-base';
import {Animated, Dimensions, View} from 'react-native';
import {SceneMap, TabView} from 'react-native-tab-view';
import {useTranslation} from 'react-i18next';
// import ProductionPage from '../pages/passportPages/ProductionPage';
import DistributionPage from '../pages/passportPages/DistributionPage';
import InformationPage from '../pages/passportPages/InformationPage';
// import MaterialsPage from '../pages/passportPages/MaterialsPage';
import {ParsedProduct} from '../types/types';
import MaterialsPage from '../pages/passportPages/MaterialsPage';
import ProductionPage from '../pages/passportPages/ProductionPage';

const initialLayout = {
  width: Dimensions.get('window').width,
  height: 1,
};

type Props = {
  passportProduct: Partial<ParsedProduct>;
};

function TabBar(props: Props) {
  const {t} = useTranslation();
  const theme = useTheme();

  const [index, setIndex] = React.useState(0);

  const [currentPage, setCurrentPage] = useState<string>('first');

  const renderScene = SceneMap({
    first: () => (
      <View style={{display: currentPage == 'first' ? 'flex' : 'none'}}>
        <InformationPage passportProduct={props.passportProduct} />
      </View>
    ),
    third: () => (
      <View style={{display: currentPage == 'third' ? 'flex' : 'none'}}>
        <MaterialsPage passportProduct={props.passportProduct} />
      </View>
    ),
    fourth: () => (
      <View style={{display: currentPage == 'fourth' ? 'flex' : 'none'}}>
        <ProductionPage passportProduct={props.passportProduct} />
      </View>
    ),
    five: () => (
      <View style={{display: currentPage == 'five' ? 'flex' : 'none'}}>
        <DistributionPage passportProduct={props.passportProduct} />
      </View>
    ),
  });

  const initialRoutes: {key: string; title: string; field: string}[] = [
    {
      key: 'first',
      title: t('Información'),
      field: 'information',
    },
    // {
    //   key: 'second',
    //   title: 'DISEÑO',
    // },
    {
      key: 'third',
      title: t('Materiales'),
      field: 'materials',
    },
    {
      key: 'fourth',
      title: t('Producción'),
      field: 'production',
    },
    {
      key: 'five',
      title: t('Distribución'),
      field: 'distribution',
    },
    //   {
    //     key: 'six',
    //     title: 'CONSUMO',
    //   },
    //   {
    //     key: 'seven',
    //     title: 'RECOGIDA',
    //   },
    //   {
    //     key: 'eight',
    //     title: 'RECICLAJE',
    //   },
  ];

  const generateRoutes = () => initialRoutes.filter(({field}) => !!props.passportProduct[field]).map(({key, title}) => ({key, title}));

  const [routes, setRoutes] = React.useState(generateRoutes());

  const windowDimensions = Dimensions.get('window');
  const isLargeScreen = windowDimensions.width >= 768;

  useEffect(() => setRoutes(generateRoutes()), [props.passportProduct]);

  const renderTabBar = (rederProps: any) => {
    const inputRange = rederProps.navigationState.routes.map((x: any, i: number) => i);

    const handleSelect = (itemValue: string) => {
      const selectedIndex = rederProps.navigationState.routes.findIndex((route: {key: string}) => route.key === itemValue);
      setIndex(selectedIndex);
      setCurrentPage(itemValue);
    };

    return (
      <Box flexDirection="row">
        {isLargeScreen ? (
          rederProps.navigationState.routes.map((route: any, i: number) => {
            const opacity =
              rederProps.navigationState.routes.length > 1
                ? rederProps.position.interpolate({
                    inputRange,
                    outputRange: inputRange.map((inputIndex: number) => (inputIndex === i ? 1 : 0.5)),
                  })
                : 1;
            const color = index === i ? theme.colors.primary['500'] : theme.colors.coolGray['500'];
            const borderColor = index === i ? 'primary.500' : 'coolGray.200';
            const fontWeight = index === i ? '900' : 'normal';
            return (
              <Pressable
                key={route.key}
                flex={1}
                onPress={() => {
                  setIndex(i);
                  setCurrentPage(route.key);
                }}>
                <Box borderBottomWidth="3" borderColor={borderColor} flex={1} alignItems="center"
p="3">
                  <Animated.Text
                    style={{
                      color,
                      opacity,
                      textTransform: 'uppercase',
                      fontWeight,
                    }}>
                    {route.title}
                  </Animated.Text>
                </Box>
              </Pressable>
            );
          })
        ) : (
          <HStack flex={1} justifyContent="space-between" alignItems="center" borderBottomWidth={2} borderBottomColor="coolGray.300" paddingBottom={2}>
            <Text bold fontSize="sm">
              {rederProps.navigationState.routes[index]?.title.toUpperCase()}
            </Text>
            <Menu
              placement="bottom right"
              trigger={triggerProps => (
                <Text {...triggerProps} color="primary.500" bold>
                  {t('Abrir menú')}
                </Text>
              )}>
              {rederProps.navigationState.routes.map((route: {key: string; title: string}) => (
                <Menu.Item key={route.key} onPress={() => handleSelect(route.key)}>
                  {route.title}
                </Menu.Item>
              ))}
            </Menu>
          </HStack>
        )}
      </Box>
    );
  };

  return (
    <TabView
      style={{flexBasis: 'fit-content'}}
      navigationState={{
        index,
        routes,
      }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={initialLayout}
      lazy
      // animationEnabled={false}
      swipeEnabled={false}
    />
  );
}

export default TabBar;
