import * as _ from 'lodash';

const sourceTestJson = {
  productName: {string: 'Patata'},
  productDescription: {string: 'la descriptione'},
  properties: [{Test1: 'AAAA'}, {Test2: 'AAAA'}],
  productQr: 'URL PRODUCT',
  derivatedProductMaterials: [
    {
      productName: {string: 'Hola'},
      image: {referencedFileURL: {value: 'urlTest'}},
      summary: [{'label 1': '1234'}, {'label 1': '1234'}],
    },
  ],
  productImages: ['laksjdfh', 'asdfasdf'],
  circularityIndex: {
    recicledMaterialUsed: 'R MATERIAL',
    wasteValue: 123445,
    carbonFootprint: {
      value: 123124,
      unitCode: 'Co2',
    },
    co2savingsByCircularPurchase: {
      savings: {
        value: 534215235,
        unitCode: 'Co2',
      },
      breakdown: [
        {
          data: {Co2: '534215235'},
          percent: 12,
        },
        {
          data: {Co2: '534215235'},
          percent: 82,
        },
      ],
    },
  },
  // INFORMATION
  information: {
    productOrigin: {
      title: 'string',
      description: 'string',
      place: {
        geo: {
          latitude: 'string',
          longitude: 'string',
        },
      },
    },
    properties: {
      data: [{key: 'value'}],
      certificates: [
        {
          certificationStandard: {
            string: 'certificationStandard',
          },
          certificationURI: {
            value: 'certificationURI',
          },
        },
      ],
    },
    carbonFootprintComparisons: [
      {
        data: {
          unitCode: 'string',
          value: 1243,
        },
        percent: 99,
      },
    ],
    tracedMaterial: 99,
    route: [
      {
        bizLocation: 'bizLocation',
        image: 'image',
      },
    ],
  },
  // MATERIAL
  material: {
    materialOrigin: [{label: 'string', percentage: 99}],
    circularityIndicator: 122345,
    wasteNearEndLife: [{label: 'string', percentage: 99}],
    materialsUsed: [{label: 'string', percentage: 99}],
    valuesInflowSankey: {
      circular: 95,
      linear: 5,
    },
    valuesBreakdownOfMassSankey: {
      recoveryPotentialInput: 1000,
      linearOutflowInput: 200,
      actualRecoveryInput: 700,
    },
    derivatedProductTraceableMaterials: [
      {
        productId: '6502340asdlg234',
        label: '1a y 2a Chatarra',
      },
      {
        productId: '7346738sdfnfhjs',
        label: 'Fragmentada',
      },
    ],
    materialOriginMap: ['Spain', 'Canada', 'Spain'],
  },
  // PRODUCTION
  production: {
    productImages: ['image1', 'image2'],
    productionRenewableEnergy: [{label: 'label', percentage: 99}],
    materialOrigin: [{label: 'label', percentage: 99}],
    circularityIndex: {
      carbonFootprint: {
        value: 123124,
        unitCode: 'Co2',
      },
      co2savingsByCircularPurchase: {
        savings: {
          value: 534215235,
          unitCode: 'Co2',
        },
        breakdown: [
          {
            data: {Co2: '534215235'},
            percent: 12,
          },
          {
            data: {Co2: '534215235'},
            percent: 82,
          },
        ],
      },
    },
    productionWaste: [{label: 'label', percentage: 99}],
    event: [
      {
        bizLocation: 'location',
        images: ['image event1'],
        description: 'description super llarga',
        materialOrigin: [{label: 'label', percentage: 99}],
        carbonFootprint: {
          value: 123124,
          unitCode: 'Co2',
        },
        co2savingsByCircularPurchase: {
          savings: {
            value: 534215235,
            unitCode: 'Co2',
          },
          breakdown: [
            {
              data: {Co2: '534215235'},
              percent: 12,
            },
            {
              data: {Co2: '534215235'},
              percent: 82,
            },
          ],
        },
        productionWaste: [{label: 'label', percentage: 99}],
        productionEnergy: {
          breakdown: [
            {
              label: 'string',
              units: 'string',
              value: 1234,
            },
          ],
        },
      },
    ],
  },
  // DISTRIBUTION
  distribution: {
    traceabilityRoute: {
      locations: [
        {
          image: 'image location',
          geo: {
            latitude: '41.5',
            longitude: '3.14',
          },
        },
      ],
      sections: [
        {
          filter: 'filter',
          route: [{lat: '12.3', lng: '43.2'}],
        },
      ],
    },
    circularityIndex: {
      carbonFootprint: {
        value: 123124,
        unitCode: 'Co2',
      },
      co2savingsByCircularPurchase: {
        savings: {
          value: 534215235,
          unitCode: 'Co2',
        },
        breakdown: [
          {
            data: {Co2: '534215235'},
            percent: 12,
          },
          {
            data: {Co2: '534215235'},
            percent: 82,
          },
        ],
      },
      carbonFootprintTnKm: [{label: 'label', value: 'value'}],
    },
    transportTypes: [{label: 'holas', percentage: 99}],
    transportRenewableEnergy: [{label: 'holas 2', percentage: 99}],
    traceabilitySections: [
      {
        co2Generated: 'co2Generated',
        description: 'description',
        ierCode: 'ierCode',
        dangerProperties: 'dangerProperties',
        treatment: 'treatment',
        weight: 'weight',
        eventTime: '09-10-2022',
        distance: 'distance',
        transport: {
          type: 'cotxe',
          shipper: 'paco pepe',
          vehicle: {
            id: '12345',
            propulsion: 'nuclear',
            weight: '2000kg',
          },
        },
        locations: [
          {
            image: 'image location',
            geo: {
              latitude: '41.5',
              longitude: '3.14',
            },
          },
        ],
      },
    ],
  },
};

const mappinJson = {
  name: 'name',
  id: '@id',
  description: 'description',
  productQr: 'url',
  properties: 'propertyShort',
  images: 'images',
  image: 'image',
  gtin: 'gtin',
  'circularityIndex.recycledMaterialUsagePercent': 'circularityIndex.recicledMaterialUsed',
  'circularityIndex.wasteValorizationPercent': 'circularityIndex.wasteValue',
  'circularityIndex.carbonFootprint.value': 'circularityIndex.carbonFootprint.value',
  'circularityIndex.carbonFootprint.units': 'circularityIndex.carbonFootprint.unitCode',
  'circularityIndex.co2Reduction.value': 'circularityIndex.co2savingsByCircularPurchase.savings.value',
  'circularityIndex.co2Reduction.units': 'circularityIndex.co2savingsByCircularPurchase.savings.unitCode',
  'circularityIndex.co2Reduction.breakdown[].data': 'circularityIndex.co2savingsByCircularPurchase.breakdown[].data',
  'circularityIndex.co2Reduction.breakdown[].percent': 'circularityIndex.co2savingsByCircularPurchase.breakdown[].percent',
  'information.origin.coordinates.latitude': 'information.productOrigin.latitude',
  'information.origin.coordinates.longitude': 'information.productOrigin.longitude',
  'information.properties.data': 'information.properties.data',
  'information.properties.certificates[].label': 'information.properties.certificates[].certificationStandard.string',
  'information.properties.certificates[].link': 'information.properties.certificates[].certificationURI.value',
  'information.carbonFootprintComparisons[].title': 'information.carbonFootprintComparisons[].data.unitCode',
  'information.carbonFootprintComparisons[].value': 'information.carbonFootprintComparisons[].data.value',
  'information.carbonFootprintComparisons[].percent': 'information.carbonFootprintComparisons[].percent',
  'information.trackedMaterialPercent': 'information.tracedMaterial',
  'materials.materialOrigin[].label': 'material.materialOrigin[].label',
  'materials.materialOrigin[].percentage': 'material.materialOrigin[].percentage',
  'materials.circularityIndicator': 'material.circularityIndicator',
  'materials.wasteNearEndLife[].label': 'material.wasteNearEndLife[].label',
  'materials.wasteNearEndLife[].percentage': 'material.wasteNearEndLife[].percentage',
  'materials.materialsUsedBeakdown[].label': 'material.materialsUsed[].label',
  'materials.materialsUsedBeakdown[].percentage': 'material.materialsUsed[].percentage',
  'materials.materialsUsed[].label': 'material.materials[].label',
  'materials.materialsUsed[].percentage': 'material.materials[].percentage',
  'materials.valuesInflowSankey.circular': 'material.valuesInflowSankey.circular',
  'materials.valuesInflowSankey.linear': 'material.valuesInflowSankey.linear',
  'materials.valuesBreakdownOfMassSankey.recoveryPotentialInput': 'material.valuesBreakdownOfMassSankey.recoveryPotentialInput',
  'materials.valuesBreakdownOfMassSankey.linearOutflowInput': 'material.valuesBreakdownOfMassSankey.linearOutflowInput',
  'materials.valuesBreakdownOfMassSankey.actualRecoveryInput': 'material.valuesBreakdownOfMassSankey.actualRecoveryInput',
  'materials.derivatedProductTraceableMaterials[].label': 'material.derivatedProductTraceableMaterials[].label',
  'materials.derivatedProductTraceableMaterials[].productId': 'material.derivatedProductTraceableMaterials[].productId',
  'materials.materialOriginMap': 'material.materialOriginMap',
  'production.init': '@id',
  // 'production.productionRenewableEnergy[].label': 'production.productionRenewableEnergy[].label',
  // 'production.productionRenewableEnergy[].percentage': 'production.productionRenewableEnergy[].percentage',
  // 'production.materialOrigin[].label': 'production.materialOrigin[].label',
  // 'production.materialOrigin[].percentage': 'production.materialOrigin[].percentage',
  // 'production.circularityIndex.carbonFootprint.value': 'production.circularityIndex.carbonFootprint.value',
  // 'production.circularityIndex.carbonFootprint.unitCode': 'production.circularityIndex.carbonFootprint.unitCode',
  // 'production.circularityIndex.co2savingsByCircularPurchase.savings.value': 'production.circularityIndex.co2savingsByCircularPurchase.savings.value',
  // 'production.circularityIndex.co2savingsByCircularPurchase.savings.unitCode': 'production.circularityIndex.co2savingsByCircularPurchase.savings.unitCode',
  // 'production.circularityIndex.co2savingsByCircularPurchase.breakdown[].data': 'production.circularityIndex.co2savingsByCircularPurchase.breakdown[].data',
  // 'production.circularityIndex.co2savingsByCircularPurchase.breakdown[].percent': 'production.circularityIndex.co2savingsByCircularPurchase.breakdown[].percent',
  // 'production.productionWaste[].label': 'production.productionWaste[].label',
  // 'production.productionWaste[].percentage': 'production.productionWaste[].percentage',
  // 'production.event[].bizLocation': 'production.event[].bizLocation',
  // 'production.event[].images': 'production.event[].images',
  // 'production.event[].imageGroup': 'production.event[].imageGroup',
  // 'production.event[].description': 'production.event[].description',
  // 'production.event[].materialOrigin[].label': 'production.event[].materialOrigin[].label',
  // 'production.event[].materialOrigin[].percentage': 'production.event[].materialOrigin[].percentage',
  // 'production.event[].carbonFootprint.value': 'production.event[].carbonFootprint.value',
  // 'production.event[].carbonFootprint.unitCode': 'production.event[].carbonFootprint.unitCode',
  // 'production.event[].co2savingsByCircularPurchase.savings.value': 'production.event[].co2savingsByCircularPurchase.savings.value',
  // 'production.event[].co2savingsByCircularPurchase.savings.unitCode': 'production.event[].co2savingsByCircularPurchase.savings.unitCode',
  // 'production.event[].co2savingsByCircularPurchase.breakdown[].data': 'production.event[].co2savingsByCircularPurchase.breakdown[].data',
  // 'production.event[].co2savingsByCircularPurchase.breakdown[].percent': 'production.event[].co2savingsByCircularPurchase.breakdown[].percent',
  // 'production.event[].productionWaste[].percentage': 'production.event[].productionWaste[].percentage',
  // 'production.event[].productionWaste[].label': 'production.event[].productionWaste[].label',
  // 'production.event[].productionEnergy.breakdown[].label': 'production.event[].productionEnergy.breakdown[].label',
  // 'production.event[].productionEnergy.breakdown[].units': 'production.event[].productionEnergy.breakdown[].units',
  // 'production.event[].productionEnergy.breakdown[].value': 'production.event[].productionEnergy.breakdown[].value',
  // 'production.event[].certificates[].label': 'production.event[].certificates[].certificationStandard.string',
  // 'production.event[].certificates[].link': 'production.event[].certificates[].certificationURI.value',
  'distribution.circularityIndex.carbonFootprint.value': 'distribution.circularityIndex.carbonFootprint.value',
  'distribution.circularityIndex.carbonFootprint.unitCode': 'distribution.circularityIndex.carbonFootprint.unitCode',
  'distribution.circularityIndex.co2savingsByCircularPurchase.savings.value': 'distribution.circularityIndex.co2savingsByCircularPurchase.savings.value',
  'distribution.circularityIndex.co2savingsByCircularPurchase.savings.unitCode': 'distribution.circularityIndex.co2savingsByCircularPurchase.savings.unitCode',
  'distribution.circularityIndex.co2savingsByCircularPurchase.breakdown[].data': 'distribution.circularityIndex.co2savingsByCircularPurchase.breakdown[].data',
  'distribution.circularityIndex.co2savingsByCircularPurchase.breakdown[].percent': 'distribution.circularityIndex.co2savingsByCircularPurchase.breakdown[].percent',
  'distribution.circularityIndex.carbonFootprintTnKm': 'distribution.circularityIndex.carbonFootprintTnKm',
  'distribution.transportTypes[].label': 'distribution.transportTypes[].label',
  'distribution.transportTypes[].percentage': 'distribution.transportTypes[].percentage',
  'distribution.transportRenewableEnergy[].label': 'distribution.transportRenewableEnergy[].label',
  'distribution.transportRenewableEnergy[].percentage': 'distribution.transportRenewableEnergy[].percentage',
  'distribution.traceabilitySections[].co2Generated': 'distribution.traceabilitySections[].co2Generated',
  'distribution.traceabilitySections[].description': 'distribution.traceabilitySections[].description',
  'distribution.traceabilitySections[].ierCode': 'distribution.traceabilitySections[].distribution.traceabilitySections.0.ierCode',
  'distribution.traceabilitySections[].dangerProperties': 'distribution.traceabilitySections[].distribution.traceabilitySections.0.dangerProperties',
  'distribution.traceabilitySections[].treatment': 'distribution.traceabilitySections[].distribution.traceabilitySections.0.treatment',
  'distribution.traceabilitySections[].weight': 'distribution.traceabilitySections[].distribution.traceabilitySections.0.weight',
  'distribution.traceabilitySections[].eventTime': 'distribution.traceabilitySections[].distribution.traceabilitySections.0.eventTime',
  'distribution.traceabilitySections[].distance': 'distribution.traceabilitySections[].distance',
  'distribution.traceabilitySections[].transport.type': 'distribution.traceabilitySections[].transport.type',
  'distribution.traceabilitySections[].transport.shipper': 'distribution.traceabilitySections[].transport.shipper',
  'distribution.traceabilitySections[].transport.vehicle.id': 'distribution.traceabilitySections[].transport.vehicle.id',
  // 'distribution.traceabilitySections[].transport.vehicle.propulsion': 'distribution.traceabilitySections[].transport.vehicle.propulsion',
  'distribution.traceabilitySections[].transport.vehicle.weight': 'distribution.traceabilitySections[].transport.vehicle.weight',
  'distribution.traceabilitySections[].Origin.name': 'distribution.traceabilitySections[].Origin.name',
  'distribution.traceabilitySections[].Origin.image': 'distribution.traceabilitySections[].Origin.image',
  'distribution.traceabilitySections[].Destiny.name': 'distribution.traceabilitySections[].Destiny.name',
  'distribution.traceabilitySections[].Destiny.image': 'distribution.traceabilitySections[].Destiny.image',
  'distribution.traceabilitySections[].organizationOrigin.name': 'distribution.traceabilitySections[].organizationOrigin.name',
  'distribution.traceabilitySections[].organizationOrigin.image': 'distribution.traceabilitySections[].organizationOrigin.url',
  'distribution.traceabilitySections[].organizationDestiny.name': 'distribution.traceabilitySections[].organizationDestiny.name',
  'distribution.traceabilitySections[].organizationDestiny.image': 'distribution.traceabilitySections[].organizationDestiny.url',
  'distribution.traceabilitySections[].co2savingsByCircularPurchase.savings.value': 'distribution.traceabilitySections[].co2savingsByCircularPurchase.savings.value',
  'distribution.traceabilitySections[].co2savingsByCircularPurchase.savings.unitCode.': 'distribution.traceabilitySections[].co2savingsByCircularPurchase.savings.unitCode',
  'distribution.traceabilitySections[].co2savingsByCircularPurchase.breakdown[].data': 'distribution.traceabilitySections[].co2savingsByCircularPurchase.breakdown[].data',
  'distribution.traceabilitySections[].co2savingsByCircularPurchase.breakdown[].percent':
    'distribution.traceabilitySections[].co2savingsByCircularPurchase.breakdown[].percent',
  'distribution.traceabilitySections[].route.coordinates': 'distribution.traceabilitySections[].route.geometry.coordinates',
  // CAMPS GENERICS BDD
  _id: '_id',
  created: 'created',
  // //! ADDITIONAL FEATURES FROM SCHEMA &  -> ORGANIZATION (referencies a labels de organització (imatgesOrigen etc.))
  // 'organization.name': 'organization.name.Text',
  // 'organization.logo': 'organization.logo',
  // 'organization.url': 'organization.url.URL',
  // 'organization.address.Count': 'organization.address.addressCount',
  // 'organization.address.Locality': 'organization.address.addressLocality',
  // 'organization.address.Region': 'organization.address.addressRegion',
  // 'organization.address.PostalCode': 'organization.address.PostalCode',
  // 'organization.address.StreetAdress': 'organization.address.StreetAdress',
  // //! ADDITIONAL FEATURES FROM SCHEMA &  -> PLACE
  // 'place.address.Country': 'place.address.addressCountry',
  // 'place.address.Locality': 'place.address.addressLocality',
  // 'place.address.Region': 'place.address.addressRegion',
  // 'place.address.PostalCode': 'place.address.PostalCode',
  // 'place.address.StreetAdress': 'place.address.StreetAdress',
  // 'place.globalLocationNumber': 'place.globalLocationNumber',
  // 'place.latitude': 'place.latitude',
  // 'place.longitude': 'place.longitude',
  // 'place.image': 'place.image',
  // 'place.name': 'place.name',
};

export const mapObject = (source: any, mapping: Record<string, string>): any => {
  const target = {};
  const mappedArrays: string[] = [];

  for (const entry of Object.entries(mapping)) {
    const sourcePath = entry[1];
    const targetPath = entry[0];

    if (sourcePath.indexOf('[]') === -1) {
      _.set(target, targetPath, _.get(source, sourcePath));
    } else if (!mappedArrays.includes(sourcePath.split('[]')[0])) {
      // Array
      const sourceArrayPath = sourcePath.split('[]')[0];
      const targetArrayPath = targetPath.split('[]')[0];

      const arrayMapping = Object.entries(mapping)
        .filter(e => e[0].split('[]')[0] === targetArrayPath) // All mappings starting with array[]
        .map(e => [e[0].substring(e[0].indexOf('[]') + 3), e[1].substring(e[1].indexOf('[]') + 3)]) // Remove all before first array in source and target
        .reduce((mappings, newEntry) => ({...mappings, [newEntry[0]]: newEntry[1]}), {}); // Join entries in new object

      const sourceValues = _.get(source, sourceArrayPath);
      const targetValues = [];
      if (sourceValues !== undefined) {
        for (const value of sourceValues) {
          const mappedArrayValue = mapObject(value, arrayMapping);
          targetValues.push(mappedArrayValue);
        }
      }
      _.set(target, targetArrayPath, targetValues);
      mappedArrays.push(sourceArrayPath);
    }
  }
  return target;
};

export const testMapping = () => mapObject(sourceTestJson, mappinJson);

export const Mapping = (source: any) => mapObject(source, mappinJson);
