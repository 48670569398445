import {FC} from 'react';
import {Stack, Text, View, useBreakpointValue} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox} from './GenericBox';

type Section = JSX.Element;
type SectionOrEmpty = Section | '';

export type Props = {
  sections: Section[];
};

function splitArrayByChunks(array: SectionOrEmpty[], chunkSize: number, isTableBoxVertical: boolean): SectionOrEmpty[][] {
  const chunks: SectionOrEmpty[][] = [];
  const numChunks = Math.ceil(array.length / chunkSize);

  for (let i = 0; i < numChunks; i++) {
    const chunk = array.slice(i * chunkSize, (i + 1) * chunkSize);

    while (!isTableBoxVertical && chunk.length < chunkSize) {
      chunk.push('');
    }

    chunks.push(chunk);
  }

  return chunks;
}

export const TraceabilityGrid: FC<Props> = props => {
  const {t} = useTranslation();

  const isTableBoxVertical = useBreakpointValue({base: true, lg: false});
  const sections = splitArrayByChunks(props.sections, 3, isTableBoxVertical);

  if (!sections || !sections.length) {
    return (
      <GenericBox>
        <Text>{t(`No se ha encontrado ninguna ruta`)}</Text>
      </GenericBox>
    );
  }

  return (
    <Stack direction={['column']} space={isTableBoxVertical ? 5 : 10}>
      {sections.map(chunk => (
        <Stack direction={['column', 'column', 'column', 'row']} space={5}>
          {chunk.map((section, index) => (
            <View flex={1} key={index}>
              {section === '' ? null : section}
            </View>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
