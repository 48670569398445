import {FC} from 'react';
import {Text, View} from 'native-base';
import {GenericBox} from '../GenericBox';
import {ComponentPie} from './ComponentPie';
import ComponentValuePercent from './ComponentValuePercent';
import {Percent} from '../../types/types';

type Series = {
  label: string;
  value: number;
};

type Props = {
  title?: string;
  description?: string;
  pieChart: {
    data: Series[];
  };
  percents: {
    title?: string;
    data: Percent[];
  };
};

const ComponentPieWithBottomPercents: FC<Props> = ({title, description, pieChart, percents}) => {
  if (!percents) {
    return null;
  }

  return (
    <GenericBox title={title} description={description}>
      <View alignSelf="center">
        <ComponentPie isNotBoxed hideLegend data={pieChart.data} />
      </View>
      {percents.title ? (
        <Text fontSize="md" fontWeight="bold" pl={4} pt={4}>
          {percents.title}
        </Text>
      ) : null}
      <ComponentValuePercent isNotBoxed percentages={percents.data} />
    </GenericBox>
  );
};

export default ComponentPieWithBottomPercents;
