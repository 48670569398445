import {FC} from 'react';
import {Progress, Text, View} from 'native-base';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';
import {Percent} from '../../types/types';

type Props = GenericBoxProps & {
  percentage: Percent;
  isNotBoxed?: boolean;
};

function fixPercentNumber(percent: number): number {
  let fixedPercent: number = percent;
  fixedPercent = Math.min(fixedPercent, 100);
  fixedPercent = Math.max(fixedPercent, 0);
  return Math.round(fixedPercent);
}

export const ComponentPercentBar: FC<Props> = ({percentage, isNotBoxed = false, ...genericBoxProps}) => {
  if (!percentage) {
    return null;
  }

  const render = (
    <View marginBottom={4}>
      <View flexDirection="row" marginBottom={2}>
        <View flex={1}>
          <Text>{percentage.title}</Text>
        </View>
        <View flex={1}>
          <Text textAlign="right" fontWeight="bold">
            {percentage.value}
          </Text>
        </View>
      </View>
      <View>
        <Progress value={fixPercentNumber(percentage.percent)} colorScheme={percentage.colorScheme} />
      </View>
    </View>
  );

  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};
