import {FC, useEffect, useState} from 'react';
import {Icon, IconButton, Stack, Text, View, useTheme} from 'native-base';
import {MyNewMapComponent} from '@bri/shared-components';
import {useTranslation} from 'react-i18next';
import {GenericBox} from './GenericBox';
import Config from '../config/config';
import AdalmoIcon from '../config/adalmo-font.js';
import {getAllMapIcons, getIconAsPathData, getMapIconForProperty} from '../utils/iconsMap';

type Props = {
  title: string;
  description?: string;
  icon?: JSX.Element;

  showFilter: boolean;
  locations?: Array<{
    lat: number;
    lng: number;
    image: string;
  }>
  arrows?: {
    route: {lat: number, lng: number}[];
    filter: string;
  }[];
};

type Arrow = {
  route: {lat: number, lng: number}[]
  filter: string;
  parameters?: google.maps.PolylineOptions;
  index: string;
  visible: boolean;
}

type Location = {
  coords: {lat: number; lng: number};
  icon?: {symbol: google.maps.Symbol; anchor: {x: number; y: number}};
  iconImage?: google.maps.Icon;
  info?: {title: string; description: string};
}

export const Map: FC<Props> = props => {
  const [t] = useTranslation();
  const allFilters = getAllMapIcons();
  const [filtersEnabled, setFiltersEnabled] = useState<string[]>(allFilters);
  const [arrows, setArrows] = useState<Arrow[]>(); // Array with the indexs of the arrows to show
  const [locations, setLocations] = useState<any>();
  const theme = useTheme();
  const arrowIcon = getIconAsPathData("angle-up-solid");
  const circleIcon = getIconAsPathData("circle");
  

  // Set markers
  useEffect(()=>{
    const locationMarkers: Location[] = [];
    const size = 40;
    props.locations?.forEach(location => {
      locationMarkers.push({
        coords: {...location},
        iconImage: {url: (`${location.image  }#marker_rounded`), anchor: {x: size/2, y: size/2} as google.maps.Point, scaledSize: {width: size, height: size} as google.maps.Size}
      })
    });
    setLocations(locationMarkers);
  }, [])

  // Update arrows every time the filters are changed
  useEffect(() => {
    const newArrows = new Array<Arrow>;
      props.arrows?.map((arrow, index) => {
        const showArrow = filtersEnabled != undefined && filtersEnabled.some(item => item == arrow.filter);
          newArrows.push({
            route: arrow.route,
            filter: getMapIconForProperty(arrow.filter),
            index: index.toString(),
            visible: showArrow
          });
      });
    setArrows(newArrows);

  }, [filtersEnabled])
  
  // Manage behaviour on filter button click
  const onFilterButtonClick = (filterChanged: string) => {
    let newFiltersEnabled = [...filtersEnabled];
    const isFilterChangedEnabled = filtersEnabled.includes(filterChanged);
    if(isFilterChangedEnabled){
      newFiltersEnabled = filtersEnabled.filter(filter => filter != filterChanged);
    }
    else{
      newFiltersEnabled.push(filterChanged);
    }
    setFiltersEnabled(newFiltersEnabled);
  }


  if (!locations || !locations.length) {
    return (      
    <GenericBox title={props.title} description={props.description}>
    <Text>{t(`No se ha encontrado ${props.title}`)}</Text>
    </GenericBox>);
  }

  return(
    <GenericBox {...props}>
      <View flex={1}> 
        <MyNewMapComponent
          apikey={Config.googleMaps.apiKey}
          locations={locations}
          rounded={5}
          containerStyle={{minHeight: '350px'}}
          polylines={
            arrows?.map((arrow) => ({
                index: arrow.index,
                options: {
                  geodesic: false,
                  visible: arrow.visible,
                  path: [...arrow.route],
                  strokeColor: theme.colors.primary[600],
                  strokeWeight: 3,
                  strokeOpacity: 1,
                  icons: [
                    {
                      icon: {path: arrowIcon.path, anchor: {x: arrowIcon.width/2, y: 500} as google.maps.Point, fillOpacity: 1, fillColor: theme.colors.primary[600], scale: 0.022, rotation: 0},
                      offset: '25%'
                    },
                    {
                      icon: {path: arrowIcon.path, anchor: {x: arrowIcon.width/2, y: 500} as google.maps.Point, fillOpacity: 1, fillColor: theme.colors.primary[600], scale: 0.022, rotation: 0},
                      offset: '75%'
                    },
                    {
                      icon: {path: circleIcon.path, anchor: {x: circleIcon.width/2, y: 500} as google.maps.Point, fillColor: theme.colors.white, strokeColor: theme.colors.white, fillOpacity: 1, scale: 0.022 * 1.9},
                      offset: '50%',
                      fixedRotation: true
                    },
                    {
                      icon: {path: getIconAsPathData(arrow.filter).path, anchor: {x: getIconAsPathData(arrow.filter).width/2, y: 500} as google.maps.Point, fillColor: theme.colors.primary[600], fillOpacity: 1, strokeWeight: 0, scale: 0.022},
                      offset: '50%',
                      fixedRotation: true
                    }
                  ]
              }
            }))}
        />
        </View>

        {props.showFilter && (
            <Stack direction="row" mt={4} space={2} alignItems="center">
              <Text>{t("Filtrar transporte")}</Text>
              {
                allFilters.map((filter) => {
                  let returnIcon = <></>
                    returnIcon = 
                      <IconButton
                        icon={<Icon as={AdalmoIcon} name={filter} size={5} color={filtersEnabled.includes(filter) ? theme.colors.primary[600] : theme.colors.gray[600]}/>}
                        onPress={() => {onFilterButtonClick(filter)}}
                      />;
                  return returnIcon;
                })
              }
            </Stack>
        )}
      
    </GenericBox>
  );
};