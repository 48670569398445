import {HStack, Icon, Pressable, Stack, Text} from 'native-base';
import {FC} from 'react';
import AdalmoIcon from '../config/adalmo-font.js';
import {GenericBox, Props as GenericBoxProps} from './GenericBox';

type Item = {
  label: string;
  onClick: () => void;
};

type IconProps = {
  withIcon?: true;
  icon?: JSX.Element;
};

type NoIconProps = {
  withIcon?: false;
};

type Props = {
  direction: 'vertical' | 'horizontal';
  isBoxed?: boolean;
  items: Item[];
} & GenericBoxProps &
  (IconProps | NoIconProps);

const LinkList: FC<Props> = ({isBoxed = true, direction, withIcon = true, icon, items, ...genericBoxProps}) => {
  const iconRender = () => {
    if (!withIcon) {
      return '';
    }

    return icon || <Icon as={AdalmoIcon} name="document" color="primary.600" size="md" alignSelf="center" />;
  };

  if (!items || !items.length) {
    return null;
  }

  const render = (
    <Stack direction={direction === 'vertical' ? 'column' : 'row'} flexWrap="wrap" space={direction === 'vertical' ? 'lg' : '2xl'} alignSelf="start">
      {items.map(({label, onClick}) => (
        <Pressable onPress={() => onClick()}>
          <HStack space={withIcon ? 'sm' : undefined}>
            {iconRender()}
            <Text color="primary.600" fontWeight="bold" alignSelf="center">
              {label}
            </Text>
          </HStack>
        </Pressable>
      ))}
    </Stack>
  );

  return isBoxed ? <GenericBox {...genericBoxProps}>{render}</GenericBox> : render;
};

export default LinkList;
