import {CustomDataTable, DataRowRequiredData} from '@bri/shared-components';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Heading, Icon, Pressable, VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {AntDesign} from '@native-base/icons';
import {Filters, SortCriteria, SortedFilteredPaginatedRequest} from '@bri/shared-core';
import {IProductPassportListItem} from '@bri/circularpass-adalmo-core';
import {useProductPassportsService} from '../services/ProductPassportsService';

type DataRow = DataRowRequiredData & IProductPassportListItem;

export type ListPassportsRef = {
  fetchData: () => Promise<void>;
};

type Props = {};

export const ListPassports = forwardRef<ListPassportsRef, Props>((props, ref) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const productPassportsService = useProductPassportsService();

  const [productPassports, setProductPassports] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [sort, setSort] = useState<SortCriteria>({});
  const [filters, setFilters] = useState<Filters>([]);

  async function fetchData() {
    const request: SortedFilteredPaginatedRequest = {page, pageSize, sort, filters};

    productPassportsService
      .list({...request})
      .response(resp => {
        setProductPassports(resp.results as any);
        setTotalResults(resp.total);
      })
      .error(console.error)
      .finally(() => setLoading(false));
  }

  // Expone la función al componente padre
  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  useEffect(() => {
    fetchData().catch(console.error);
  }, [page, pageSize, sort, filters]);

  if (loading) return null;

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Identificador'),
      selector: row => row.name as string,
      sortable: true,
      grow: 2,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.gtin}
        </Heading>
      ),
    },
    {
      name: t('Nombre'),
      selector: row => row.name as string,
      sortable: true,
      grow: 3,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.name}
        </Heading>
      ),
    },
    {
      name: t('Fecha creación'),
      selector: row => row.name as string,
      sortable: true,
      grow: 2,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.created}
        </Heading>
      ),
    },
    {
      name: t('Acciones'),
      center: true,
      style: customStyles,
      cell: item => (
        <Pressable
          onPress={() => {
            navigate(`/passport/${item._id}`);
          }}>
          <Icon as={AntDesign} name="eyeo" size={4} color="primary.400" />
        </Pressable>
      ),
    },
  ];
  return (
    <VStack space={2} alignItems="center">
      <CustomDataTable
        columns={columns}
        data={productPassports}
        export
        showExtensions={false}
        defaultActions={false}
        customStyles={customStyles}
        paginationServer
        sortServer
        totalRows={totalResults}
        onChangePage={newPage => setPage(newPage)}
        onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
        onSort={(newSort: SortCriteria) => setSort(newSort)}
        onFilter={setFilters}
      />
    </VStack>
  );
});

const customStyles: TableStyles = {
  tableWrapper: {
    style: {
      padding: 4,
    },
  },
  table: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  header: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  headCells: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
};
