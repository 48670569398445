import {FC} from 'react';
import {Box, Stack, Text, View} from 'native-base';

export type Props = {
  title?: string;
  description?: string;
  icon?: JSX.Element;
  isBackgroundGray?: boolean;
  overflowVisible?: boolean;
};

export const GenericBox: FC<Props> = props => (
  <Box flex={1} backgroundColor={props.isBackgroundGray ? '#F3F4F6' : 'white'} rounded="lg" overflow={props.overflowVisible ? 'visible' : 'hidden'}>
    <View p={10} flex={1} overflow="inherit">
      <Stack direction="column" flex={1} overflow="inherit">
        <Stack direction="row" justifyContent="space-between" mb={4}>
          <Stack direction="column" flex={1}>
            <Text fontSize="md" fontWeight="bold">
              {props.title}
            </Text>
            <Text fontSize="sm">{props.description}</Text>
          </Stack>
          <View flexShrink={1} pl={2} alignItems="end">
            {props.icon}
          </View>
        </Stack>
        <View flex={1} justifyItems="left">
          {props.children}
        </View>
      </Stack>
    </View>
  </Box>
);
