import {FC, useEffect, useState} from 'react';
import {View, VStack, useBreakpointValue} from 'native-base';
import {Animated, Easing, Text} from 'react-native';
import {useXarrow} from 'react-xarrows';
import {ILinearGradientProps} from 'native-base/lib/typescript/components/primitives/Box/types';
import {ResponsiveValue, ColorType} from 'native-base/lib/typescript/components/types';
import {useTranslation} from 'react-i18next';
import {DropdownSection} from './DropdownSection';
import {GenericBox, Props as GenericBoxProps} from '../components/GenericBox';

type Section = {
  title: string;
  image?: string;
  content?: JSX.Element;
  onClick?: () => void;
};

export type Props = {
  sections: Section[];
  dropdownId: string;
  sectionTitleBackgroundColor?: ResponsiveValue<ColorType | ILinearGradientProps>;
  contentMargin?: string;
  sectionHeight?: number; // in pixels
  badgeSize?: number; // in pixels
};

export const DropdownComponent: FC<Props & GenericBoxProps> = props => {
  const {t} = useTranslation();
  const [showDropdowns, setShowDropdowns] = useState<string[]>([]);
  const updateXarrow = useXarrow();
  const [dropdownOpacity] = useState(new Animated.Value(0));
  const isVertical = useBreakpointValue({base: true, lg: false});
  const sectionTitleBackgroundColor = props.sectionTitleBackgroundColor || 'white';

  useEffect(() => {
    const anySectionWithContentExists = props.sections.some(section => !!section.content);

    if (!anySectionWithContentExists) {
      return;
    }

    updateXarrow();
    Animated.timing(dropdownOpacity, {
      toValue: showDropdowns.length > 0 ? 1 : 0,
      duration: 300,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  }, [showDropdowns]);

  const toggleDropdown = (section: Section) => {
    const {title} = section;

    if (section.onClick) {
      section.onClick();
    } else if (section.content) {
      setShowDropdowns(prevState => (prevState.includes(title) ? prevState.filter(item => item !== title) : [...prevState, title]));
    }
  };

  if (!props.sections || !props.sections.length) {
    return (
      <GenericBox {...GenericBox}>
        <Text>{t(`No se han encontrado ${props.title}`)}</Text>
      </GenericBox>
    );
  }

  return (
    <View>
      <VStack space={6}>
        {props.sections.map((section, index) => (
          <View zIndex={props.sections.length - index}>
            <DropdownSection
              modeVertical={isVertical}
              key={index}
              id={index}
              dropdownId={props.dropdownId}
              title={section.title}
              next={index < props.sections.length - 1 ? index + 1 : undefined}
              imageUrl={section.image || ''}
              dropdownOpacity={dropdownOpacity}
              showDropdown={showDropdowns.includes(section.title)}
              toggleDropdown={section.content || section.onClick ? () => toggleDropdown(section) : undefined}
              content={section.content}
              contentMargin={props.contentMargin}
              sectionHeight={props.sectionHeight}
              badgeSize={props.badgeSize}
              titleBackgroundColor={sectionTitleBackgroundColor}
            />
          </View>
        ))}
      </VStack>
    </View>
  );
};

export default DropdownComponent;
