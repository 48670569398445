const DEFAULT_ICON = 'info-circle';

const ICON_PROPERTY_MAPPINGS: Record<string, string> = {
  bulto: 'package',
  colada: 'filter',
  material: 'screw',
  'tipo de material': 'screw',
  calidad: 'receipt',
  norma: 'shield',
  'dia de fabricación': 'calendar',
  diámetro: 'circles',
  'peso aproximado': 'scale',
  'huella de carbono': 'co2_cloud',
  'ahorro co2': 'co2_leaf',
  'material reciclado utilizado': 'recycle',
  'origen material': 'map-marker',
  planilla: 'waves',
  estructura: 'brick-wall',
  elemento: 'screw',
  forma: 'hexagon',
  'número di': 'receipt',
  origen: 'map-marker',
};

export function getIconForProperty(property: string): string {
  return ICON_PROPERTY_MAPPINGS[property.toLowerCase()] || DEFAULT_ICON;
}
