import React from 'react';
import {Navigate} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';

type Props = {
  children: JSX.Element;
};

const UnAuthenticated: React.FC<Props> = ({children}) => {
  const [user] = useRecoilState(loggedUser);

  return !user ? children : <Navigate to="/" replace />;
};

export default UnAuthenticated;
