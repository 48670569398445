export const mixColors = (color1: string, color2: string, weight: number): string => {
  // Parse the hex color strings to RGB values
  const hexToRgb = (hex: string) =>
    hex
      .replace(/^#/, '')
      .match(/.{2}/g)
      ?.map(value => parseInt(value, 16)) || [0, 0, 0];

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate the mixed RGB values
  const mixedRgb = rgb1.map((channel1, index) => {
    const channel2 = rgb2[index];
    const mixedChannel = Math.round(channel1 + (channel2 - channel1) * weight);
    return Math.min(255, Math.max(0, mixedChannel));
  });

  // Convert the mixed RGB values back to a hex color
  const mixedColor = `#${mixedRgb.map(value => value.toString(16).padStart(2, '0')).join('')}`;

  return mixedColor;
};

export const calcInflow = (circular: number, linear: number) => {
  if (!circular || (!linear && linear !== 0 && circular !== 0)) return undefined;
  //* circular and linear -> kg
  const totalMass = circular + linear;
  const circularInflowPerc = (circular * 100) / totalMass;
  const linearInflowPerc = (linear * 100) / totalMass;
  return {
    nodes: [
      {
        id: '1',
        category: 'Circular inflow',
        size: `${circular} Kg`,
        flow: 'Inflow',
      },
      {
        id: '2',
        category: 'Linear inflow',
        size: `${linear} Kg`,
        flow: 'Inflow',
      },
      {
        id: '3',
        category: 'Circular inflow',
        size: `${circular} Kg`,
        flow: 'Inflow',
      },
      {
        id: '4',
        category: 'Linear inflow',
        size: `${linear} Kg`,
        flow: 'Inflow',
      },
    ],
    links: [
      {source: '1', target: '3', value: circularInflowPerc.toFixed(2), flow: 'Inflow'},
      {source: '2', target: '4', value: linearInflowPerc.toFixed(2), flow: 'Inflow'},
    ],
  };
};

export const calcBreakdownOfMass = (recoveryPotentialInput: number, linearOutflowInput: number, actualRecoveryInput: number) => {
  if ((!recoveryPotentialInput || !linearOutflowInput || !actualRecoveryInput) && linearOutflowInput !== 0 && recoveryPotentialInput !== 0) return undefined;
  const totalMass = recoveryPotentialInput + linearOutflowInput;
  const recoveryPotential = (recoveryPotentialInput * 100) / totalMass;
  const linearOutflow = (linearOutflowInput * 100) / totalMass;
  const actualRecovery = (actualRecoveryInput * 100) / totalMass;
  const actualLost = 100 - actualRecovery - linearOutflow;
  return {
    nodes: [
      {
        id: '1',
        size: `${recoveryPotentialInput} Kg`,
        category: 'Recovery potential',
        flow: 'Outflow',
      },
      {
        id: '2',
        size: `${linearOutflowInput} Kg`,
        category: 'Linear outflow',
        flow: 'Outflow',
      },
      {id: '3'},
      {
        id: '4',
        size: `${actualRecoveryInput} Kg`,
        category: 'Actual recovery',
        flow: 'Outflow',
      },
      {
        id: '5',
        size: `${totalMass - actualRecoveryInput} Kg`,
        category: 'Lost potential',
        flow: 'Outflow',
      },
      {
        id: '6',
        size: `${actualRecoveryInput} Kg`,
        category: 'Circular outflow',
        flow: 'Outflow',
      },
      {
        id: '7',
        size: `${totalMass - actualRecoveryInput} Kg`,
        category: 'Linear outflow',
        flow: 'Outflow',
      },
    ],
    links: [
      {source: '1', target: '3', value: recoveryPotential.toFixed(2), flow: 'Outflow'},
      {source: '2', target: '5', value: linearOutflow.toFixed(2), flow: 'Outflow'},
      {source: '3', target: '4', value: actualRecovery.toFixed(2), flow: 'Outflow'},
      {source: '3', target: '5', value: actualLost.toFixed(2), flow: 'Outflow'},
      {source: '4', target: '6', value: actualRecovery.toFixed(2), flow: 'Outflow'},
      {source: '5', target: '7', value: (actualLost + linearOutflow).toFixed(2), flow: 'Outflow'},
    ],
  };
};
