import {FC} from 'react';
import {Image, Link} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from './GenericBox';
import logo from '../images/circulartrust_logo.png';

export type Props = GenericBoxProps & {
  imageUrl?: string;
  contract?: string;
  productGtin?: string;
};

const CircularTrustDid: FC<Props> = props => {
  const {t} = useTranslation();

  const hasAllData = props.contract && props.productGtin;
  const defaultTitle = 'CircularTrust DID';
  let link = '';

  if (hasAllData) link = `did:nft:eip255:197_erc998:${props.contract}:${props.productGtin}`;

  return (
    <GenericBox
      {...props}
      title={props.title ?? defaultTitle}
      description={hasAllData ? `did:nft:eip255:197_erc998:${props.contract}:${props.productGtin}` : (t(`No se ha encontrado ${props.title ?? defaultTitle}`) as string)}
      icon={
        <Link href={hasAllData ? link : undefined} isExternal>
          <Image src={logo} w="70px" h="70px" />
        </Link>
      }
    />
  );
};

export default CircularTrustDid;
