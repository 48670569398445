import {FC} from 'react';
import {Icon} from 'native-base';
import AdalmoIcon from '../config/adalmo-font.js';
import LinkList from './LinkList';

type Certificate = {
  name: string;
  link: string;
};

type Props = {
  certificates: Certificate[];
};

const CertificateList: FC<Props> = ({certificates}) => {
  if (!certificates || !certificates.length) {
    return null;
  }

  return (
    <LinkList
      isBoxed={false}
      direction="vertical"
      icon={<Icon as={AdalmoIcon} name="certificate" color="primary.600" size="md" alignSelf="center" />}
      items={certificates.map(({name, link}) => ({label: name, onClick: () => window.open(link, '_blank')}))}
    />
  );
};

export default CertificateList;
