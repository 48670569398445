import {Heading, Hidden, HStack, View} from 'native-base';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import {ReactElement} from 'react';

type Props = {
  title?: any;
  children: any;
  button?: ReactElement;
};
export const PageLayout = (props: Props) => {
  const [user] = useRecoilState(loggedUser);

  return (
    <div
      className="outlet"
      style={{
        zIndex: -1,
        minHeight: user ? 'calc(100vh - 60px)' : '100vh',
        marginTop: user ? 60 : 0,
      }}>
      <View flex={1} my={3} px={{base: 23, lg: 55}} py={2.5}>
        {/* Aquest View es per a que quan es fa pantalla petita i apareix la barra superior, el contingut baixi i es vegi completament */}
        {!user && (
          <Hidden from="lg">
            <View mt={20} flex={1}>
              {' '}
            </View>
          </Hidden>
        )}
        {props.title && (
          <HStack justifyContent="space-between" flex={1} alignItems="center" mb={3}>
            <Heading fontSize="2xl" my={2}>
              {props.title}
            </Heading>
            {props.button && <View flex={1}>{props.button}</View>}
          </HStack>
        )}
        {props.children}
      </View>
    </div>
  );
};
