import {FC, useRef} from 'react';
import {Text, View, useTheme} from 'native-base';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';

type Props = GenericBoxProps & {
  data: {label: string; value: number}[];
  isNotBoxed?: boolean;
  color?: string;
  defaultVertical?: boolean;
  hideLegend?: boolean;
};

export const ComponentPie: FC<Props> = ({data, isNotBoxed, defaultVertical = false, color = 'primary', hideLegend = false, ...genericBoxProps}) => {
  // const isVertical = useBreakpointValue({base: true, lg: false});
  const {t} = useTranslation();
  const theme = useTheme();
  const {colors} = theme;
  const colorPalette = colors[color as keyof typeof colors];
  const hexValues = Object.values(colorPalette);
  const startIndex = data.length < 4 ? 2 : 0;
  const colorGraphic = hexValues.slice(startIndex, hexValues.length);
  const chartRef = useRef<Chart>(null);

  if (!data || !data.length) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No hay datos que mostrar`)}</Text>
      </GenericBox>
    );
  }

  if (data) {
    const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
    if (sum === 0) {
      return (
        <GenericBox {...genericBoxProps}>
          <Text>{t(`No hay datos que mostrar`)}</Text>
        </GenericBox>
      );
    }
  }

  const labelHeight = 35;

  const values = data.map(item => item.value);
  const names = data.map(item => item.label);

  const chartOptions: ApexOptions = {
    chart: {
      type: 'pie',
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      events: {
        mounted: chart => {
          chart.windowResizeHandler();
        },
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    labels: names,
    responsive: [
      {
        breakpoint: 420,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 300,
            height: defaultVertical ? 200 + values.length * labelHeight : 150,
          },
          legend: {
            offsetX: defaultVertical ? 0 : 0,
            offsetY: defaultVertical ? 0 : -20,
          },
        },
      },
      {
        breakpoint: 500,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 300,
            height: defaultVertical ? 200 + values.length * labelHeight : 150,
          },
          legend: {
            offsetX: defaultVertical ? 0 : 0,
            offsetY: defaultVertical ? 0 : -20,
          },
        },
      },
      {
        breakpoint: 520,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 300,
            height: defaultVertical ? 250 + values.length * labelHeight : 150,
          },
          legend: {
            offsetX: defaultVertical ? 0 : 0,
            offsetY: defaultVertical ? 0 : -20,
          },
        },
      },
      {
        breakpoint: 570,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 400,
            height: defaultVertical ? 250 + values.length * labelHeight : 200,
          },
          legend: {
            offsetX: defaultVertical ? 0 : 0,
            offsetY: defaultVertical ? 0 : -20,
          },
        },
      },
      {
        breakpoint: 700,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 480,
            height: defaultVertical ? 250 + values.length * labelHeight : 200,
          },
          legend: {
            offsetX: defaultVertical ? 0 : 0,
            offsetY: defaultVertical ? 0 : -20,
          },
        },
      },
      {
        breakpoint: 1451,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 500,
            height: defaultVertical ? 250 + values.length * labelHeight : 200,
          },
          legend: {
            offsetX: defaultVertical ? 0 : -40,
            offsetY: defaultVertical ? 0 : -20,
            itemMargin: {
              vertical: 10,
              horizontal: 10,
            },
          },
        },
      },
      {
        breakpoint: 1921,
        options: {
          chart: {
            width: defaultVertical ? '100%' : 510,
            height: defaultVertical ? 350 + values.length * labelHeight : 300,
          },
          legend: {
            offsetX: defaultVertical ? 0 : -40,
            offsetY: defaultVertical ? 0 : -20,
            itemMargin: {
              vertical: 10,
              horizontal: 10,
            },
          },
        },
      },
    ],
    colors: colorGraphic,
    legend: {
      width: 250,
      height: defaultVertical ? 40 + values.length * labelHeight : undefined,
      show: !hideLegend,
      position: defaultVertical ? 'bottom' : 'right',
      horizontalAlign: defaultVertical ? 'left' : 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 6,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      formatter(label: string, options: any) {
        return `<div style="flex: 1; padding-left: 10px; padding-right: 40px">${label}</div><div><b>${options.w.globals.series[options.seriesIndex]}%</b></div>`;
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  if (!data || !data.length) {
    return null;
  }

  const render = (
    <View justifyContent="center" alignItems="center">
      <Chart ref={chartRef} options={chartOptions} series={values} type="pie" />
    </View>
  );
  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};
