import mapIcons from '../config/map-icons.json';

const DEFAULT_ICON = 'boat';

const ICON_PROPERTY_MAPPINGS: Record<string, string> = {
  train: 'train',
  airplane: 'airplane',
  truck: 'truck',
  boat: 'boat',
};

const ICON_PROPERTY_MAPPINGS_ALL: Record<string, string> = {
  train: 'train',
  airplane: 'airplane',
  camion: 'truck',
  truck: 'truck',
  barco: 'boat',
  boat: 'boat',
  avion: 'airplane',
  tren: 'train',
};

export function getAllMapIcons() {
  return Object.values(ICON_PROPERTY_MAPPINGS);
}

export function getMapIconForProperty(property: string) {
  const icon = ICON_PROPERTY_MAPPINGS_ALL[property.toLowerCase()] || ICON_PROPERTY_MAPPINGS_ALL[DEFAULT_ICON];
  return icon;
}

export function getIconAsPathData(icon: string): PathData {
  const returnIcon: PathData = {
    path: '',
    width: 0,
  };

  const iconData = mapIcons.glyphs.find(glyphIcon => glyphIcon.search.find(searchName => searchName === icon));

  if (iconData) {
    returnIcon.path = iconData.svg.path;
    returnIcon.width = iconData.svg.width;
  }

  return returnIcon;
}

export type PathData = {
  path: string;
  width: number;
};
