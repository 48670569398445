import {FC, useEffect, useState} from 'react';
import {Badge, Icon, useTheme, View, Image, Text, Center, Stack, Box} from 'native-base';
import Xarrow from 'react-xarrows';
import {Animated, TouchableOpacity} from 'react-native';
import {ILinearGradientProps} from 'native-base/lib/typescript/components/primitives/Box/types';
import {ResponsiveValue, ColorType} from 'native-base/lib/typescript/components/types';
import {getImageDimensions, getResource} from '../utils/imagesUtils';
import AdamoIcon from '../config/adalmo-font';

export type Props = {
  id: number;
  dropdownId: string;
  title: string;
  titleBackgroundColor?: ResponsiveValue<ColorType | ILinearGradientProps>;
  next: number | undefined;
  imageUrl: string;
  dropdownOpacity: Animated.Value;
  showDropdown: boolean;
  modeVertical?: boolean;
  content?: JSX.Element;
  contentMargin?: string;
  sectionHeight?: number; // in pixels
  badgeSize?: number; // in pixels
  toggleDropdown?: () => void;
};

export const DropdownSection: FC<Props> = props => {
  const theme = useTheme();
  const [h, setSectionH] = useState(70); // section height
  const [cs, setCircleSize] = useState(30); // circle size
  const [w, setW] = useState(0); // image widht
  const titleBackgroundColor = props.titleBackgroundColor || 'white';

  useEffect(() => {
    const getImageDimensionsAsync = async () => {
      try {
        const dimensions = await getImageDimensions(props.imageUrl);
        setW((dimensions.width * h) / dimensions.height);
      } catch {
        setW(0);
      }
    };
    getImageDimensionsAsync();
  }, [props.imageUrl, h]);

  useEffect(() => {
    props.sectionHeight && setSectionH(props.sectionHeight);
    props.badgeSize && setCircleSize(props.badgeSize);
  }, [props.sectionHeight, props.badgeSize]);

  const title = (
    <View height={h} flexDir="row" alignItems="center" ml={7} overflow="hidden">
      {props.content && (
        <View style={{transform: [{rotate: props.showDropdown ? '0deg' : '90deg'}]}}>
          <Icon as={AdamoIcon} name="angle_up" size={19} color={theme.colors.primary[600]} />
        </View>
      )}
      <Text ml={4} fontWeight="bold" fontSize="md">
        {props.title}
      </Text>

      <View flex={1} alignItems="flex-end" mr={props.contentMargin ? props.contentMargin : 6}>
        <Image source={getResource(props.imageUrl)} w={w * 0.7} h={h * 0.7} resizeMode="contain" />
      </View>
    </View>
  );

  const toggler = () => {
    if (!props.toggleDropdown) {
      return title;
    }

    return <TouchableOpacity onPress={props.toggleDropdown}>{title}</TouchableOpacity>;
  };

  return (
    <Stack direction="row" h={props.showDropdown ? undefined : `${h.toString()}px`} minH={props.showDropdown ? `${h.toString()}px` : undefined}>
      <View h={`${h.toString()}px`} position="absolute" justifyContent="center" left={props.modeVertical ? `${(-cs / 2).toString()}px` : 0}
zIndex={1}>
        <Center nativeID={`dropdown_${props.dropdownId}_${props.id}`}>
          <Badge
            w={`${cs.toString()}px`}
            h={`${cs.toString()}px`}
            justifyContent="center"
            alignItems="center"
            borderRadius="100%"
            borderWidth={3}
            borderColor={theme.colors.primary[600]}>
            <Text color={theme.colors.primary[600]} fontSize="md">
              {props.id + 1}
            </Text>
          </Badge>
        </Center>
      </View>

      <View size="100%" pl={props.modeVertical ? undefined : `${(cs + 10).toString()}px`} overflow="hidden">
        <Box size="100%" backgroundColor={titleBackgroundColor} rounded="lg" overflow="hidden">
          {toggler()}
          <View m={props.contentMargin ? props.contentMargin : 6}>
            {/* Here shows the content of the section */}
            <Animated.View style={{opacity: props.dropdownOpacity}}>{props.content}</Animated.View>
          </View>
        </Box>
      </View>

      {props.next && (
        <Xarrow
          start={`dropdown_${props.dropdownId}_${props.id}`}
          end={`dropdown_${props.dropdownId}_${props.next}`}
          startAnchor="bottom"
          endAnchor="top"
          path="straight"
          strokeWidth={2}
          lineColor={theme.colors.primary[600]}
          showHead={false}
        />
      )}
    </Stack>
  );
};
