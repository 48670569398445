import {Icon, Stack, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {FC, useState} from 'react';
import {IProductPassport} from '@bri/circularpass-adalmo-core';
import Header from '../../components/Header';
import AdalmoIcon from '../../config/adalmo-font.js';
import {ComponentDataSankey} from '../../components/graphs/ComponentDataSankey';
import {calcBreakdownOfMass, calcInflow} from '../../utils/sankey.Utils';
import {ComponentPie} from '../../components/graphs/ComponentPie';
import {ComponentRadialbar} from '../../components/graphs/ComponentRadialbar';
import ComponentPieWithBottomPercents from '../../components/graphs/ComponentPieWithBottomPercents';
import LinkList from '../../components/LinkList';
import ProductInformationModal from '../../components/ProductInformationModal';
import ComponentGeochartHeatmap from '../../components/graphs/ComponentGeochartHeatmap';
import {ParsedProduct} from '../../types/types';
import {maxDecimals} from '../../utils/maxDecimals';
import {CurrentTheme} from '../../theme';

type Props = {
  passportProduct: Partial<ParsedProduct>;
};

const MaterialsPage: FC<Props> = ({passportProduct}) => {
  const {t} = useTranslation();

  const [productModalId, setProductModalId] = useState<IProductPassport['@id']>('');
  const [isProductInformationModalOpen, setIsProductInformationModalOpen] = useState<boolean>(false);
  const passportMaterials = passportProduct?.materials;

  const openModal = (productId: IProductPassport['@id']) => {
    setProductModalId(productId);
    setIsProductInformationModalOpen(true);
  };

  const data = {
    valuesGraphicOrigen:
      passportMaterials?.materialOrigin?.map((item: {label: string; percentage: number}) => ({label: item.label || '', value: maxDecimals(item.percentage, 2) || 0})) || [],
    circularityIndicator: maxDecimals(passportMaterials?.circularityIndicator || 0, 2),
    valuesGraphicFinvida:
      passportMaterials?.wasteNearEndLife?.map((item: {label: string; percentage: number}) => ({label: item?.label || '', value: item?.percentage || 0})) || [],
    dataSankey1: calcInflow(passportMaterials?.valuesInflowSankey?.circular, passportProduct?.materials?.valuesInflowSankey?.linear),
    dataSankey2: calcBreakdownOfMass(
      passportMaterials?.valuesBreakdownOfMassSankey?.recoveryPotentialInput,
      passportMaterials?.valuesBreakdownOfMassSankey?.linearOutflowInput,
      passportMaterials?.valuesBreakdownOfMassSankey?.actualRecoveryInput
    ),
    materialOrigin:
      (passportMaterials?.materialOriginMap?.map((item: {[key: string]: number}) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return [key, value];
      }) as {[key: string]: any}[]) || [],
    materialsUsed: {
      chart: passportMaterials?.materialsUsed?.map((item: {label: string; percentage: number}) => ({label: item.label || '', value: item.percentage || 0})) || [],
      categories:
        passportMaterials?.materialsUsedBeakdown?.map((item: {label: string; percentage: number}) => ({
          title: item.label || '',
          value: `${maxDecimals(item.percentage, 2)}%` || '0%',
          percent: maxDecimals(item.percentage, 2) || 0,
          colorScheme: 'primary',
        })) || [],
    },
  };

  return (
    <View py={6}>
      <Header imageSource={CurrentTheme.images.information} text={t('Materiales')} icon={<Icon as={AdalmoIcon} name="truck" size={5} color="white" />} />
      <View py={6}>
        <Stack direction={['column', 'column', 'column', 'column', 'column', 'row']} marginBottom={2} space="xs">
          <ComponentPie title={t('Material de Origen')!} description={t('Origen de los materiales usados')!} data={data.valuesGraphicOrigen} />
          <ComponentRadialbar title={t('Indicador de Circularidad')!} value={data.circularityIndicator} />
          <ComponentPie title={t('Residuos al fin de vida')!} description={t('Residuos generados al llegar a fin de vida del producto')!} data={data.valuesGraphicFinvida} />
        </Stack>
        <Stack direction={{base: 'column', lg: 'row'}} space="xs" mb={2}>
          <Stack flex={1 / 4}>
            <ComponentDataSankey title={t('Inflow')!} data={data.dataSankey1} dataFlow={[t('Lineal'), t('Circular')]} width={0.194} height={300} />
          </Stack>
          <Stack flex={3 / 4}>
            <ComponentDataSankey title={t('Breakdown of mass')!} dataFlow={[t('Lineal'), t('Circular')]} data={data.dataSankey2} width={0.61} height={300} />
          </Stack>
        </Stack>
        <Stack direction={{base: 'column', md: 'row'}} space="xs" mb={2}>
          <ComponentPieWithBottomPercents
            title={t('Material')!}
            description={t('Materiales usados en la fabricación del producto')!}
            pieChart={{data: data.materialsUsed.chart}}
            percents={{
              title: t('Materiales estratégicos la UE utilizados')!,
              data: data.materialsUsed.categories,
            }}
          />
          <LinkList
            title={t('Material trazable')!}
            description={t('Clica en un material para ver información')!}
            direction="vertical"
            withIcon={false}
            items={(passportMaterials?.derivatedProductTraceableMaterials || []).map(product => ({
              label: product?.label || '',
              onClick: () => openModal(product?.productId || ''),
            }))}
          />
        </Stack>
        <ComponentGeochartHeatmap
          title={t('Origen del material')!}
          description={t('Orígenes de los materiales utilizados en la fabricación del producto')!}
          data={data.materialOrigin}
        />
      </View>

      <ProductInformationModal id={productModalId} isOpen={isProductInformationModalOpen} onClose={() => setIsProductInformationModalOpen(false)} />
    </View>
  );
};
export default MaterialsPage;
