import {Button, CloseIcon, Modal, ScrollView, Stack, Text, View} from 'native-base';
import {FC, useEffect, useRef, useState} from 'react';
import {useWindowDimensions} from 'react-native';

export type Props = {
  title?: JSX.Element | string;
  isOpen: boolean;
  onClose: any;
  heightPercent: number;
  widthPercent: number;
  minHeight?: string;
  padding?: {
    sides: string;
    top: string;
    bottom: string;
  };
  children?: JSX.Element;
};

export const GenericModal: FC<Props> = props => {
  const {height, width} = useWindowDimensions();
  const [scrollViewKey, setScrollViewKey] = useState(0);
  const modalContentRef = useRef();

  useEffect(() => {
    if (props.isOpen) {
      setScrollViewKey(scrollViewKey + 1);
    }

    // Manage events to close modal when click outside
    const checkClickOutsideModal = (e: {target: any}) => {
      if (props.isOpen && modalContentRef.current && !(modalContentRef.current as any).contains(e.target)) {
        props.onClose();
      }
    };
    document.addEventListener('mousedown', checkClickOutsideModal);

    return () => {
      document.removeEventListener('mousedown', checkClickOutsideModal);
    };
  }, [props.isOpen]);

  const title = () => {
    if (!props.title) {
      return null;
    }

    if (typeof props.title === 'string') {
      return (
        <Text fontSize="lg" fontWeight="bold" ellipsizeMode="tail" numberOfLines={1}>
          {props.title}
        </Text>
      );
    }

    return props.title as JSX.Element;
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} _overlay={{style: {position: 'fixed'} as any}}>
      <View size="100%" alignItems="center">
        <ScrollView h="100%" w={props.widthPercent ? props.widthPercent * width : '70%'} showsVerticalScrollIndicator={false} key={scrollViewKey}>
          <View
            ref={modalContentRef}
            width="100%"
            height={props.heightPercent ? props.heightPercent * height : height * 0.7}
            backgroundColor="coolGray.100"
            rounded="lg"
            my={height * ((1 - props.heightPercent) / 2)}
            px={props.padding ? props.padding.sides : '30px'}
            pt={props.padding ? props.padding.top : '20px'}
            pb={props.padding ? props.padding.bottom : '10px'}
            flex="fit-content"
            minH={props.minHeight ? props.minHeight : props.heightPercent * height}>
            {/* Content of the modal box */}
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between">
                {title()}
                <Button variant="ghost" startIcon={<CloseIcon />} onPress={props.onClose} size={10} />
              </Stack>
              <View mt="16px">{props.children}</View>
            </Stack>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
