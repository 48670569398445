import {HStack, Icon, Stack, Text, VStack, View} from 'native-base';
import {FC, useState} from 'react';
import QRCode from 'react-qr-code';
import {useTranslation} from 'react-i18next';
import LinkList from './LinkList';
import TableBox from './TableBox';
import {ComponentRadialbar} from './graphs/ComponentRadialbar';
import ComponentValue from './graphs/ComponentValue';
import ComponentValuePercent from './graphs/ComponentValuePercent';
import {ImageGallery} from './image-gallery/ImageGallery';
import AdalmoIcon from '../config/adalmo-font.js';
import SummarySheetModal from './SummarySheetModal';
import {ParsedProduct} from '../types/types';
import {Images, getResource} from '../utils/imagesUtils';
import {getIconForProperty} from '../utils/icons';

type Props = {
  id?: string;
  showTitle: boolean;
  showSummarySheets: boolean;
  passportProduct: Partial<ParsedProduct>;
};

const icon = (property: string) => <Icon as={AdalmoIcon} name={getIconForProperty(property)} color="primary.600" size="md" alignSelf="center" />;

const ProductData: FC<Props> = ({id, showTitle, showSummarySheets, passportProduct}) => {
  const {t} = useTranslation();

  const [summarySheetModalId, setSummarySheetModalId] = useState<ParsedProduct['@id']>('');
  const [isSummarySheetModalOpen, setIsSummarySheetModalOpen] = useState<boolean>(false);

  const openModal = (productId: ParsedProduct['@id']) => {
    setSummarySheetModalId(productId);
    setIsSummarySheetModalOpen(true);
  };

  const data = {
    scrap: {
      gtin: passportProduct?.gtin || '',
      name: passportProduct?.name || 'No name',
      images: passportProduct?.image ? [`${passportProduct?.image}`] || [getResource(Images.imageTemp).uri] : [getResource(Images.imageTemp).uri],
      description: passportProduct?.description || 'No Description',
      properties:
        passportProduct?.properties?.map(item => {
          const key = Object.keys(item)[0];
          return {label: t(key), value: item[key], icon: icon(key)};
        }) || [],
      summarySheets:
        passportProduct?.materials?.derivatedProductTraceableMaterials?.map((item: any) => ({
          label: item.label,
          onClick: () => openModal(item.productId),
        })) || [],
      url: passportProduct?.productQr || '',
      circularityIndex: {
        recycledMaterialUsagePercent: passportProduct?.circularityIndex?.recycledMaterialUsagePercent || 0,
        wasteValorizationPercent: passportProduct?.circularityIndex?.wasteValorizationPercent || 0,
        carbonFootprint: {
          value: +(passportProduct?.circularityIndex?.carbonFootprint?.value?.toFixed(2) || 0) || 0,
          units: passportProduct?.circularityIndex?.carbonFootprint?.units || 'CO2',
        },
        co2Reduction: {
          value: +(passportProduct?.circularityIndex?.co2Reduction?.value?.toFixed(2) || 0) || 0,
          units: passportProduct?.circularityIndex?.co2Reduction?.units || 'Co2',
          breakdown:
            passportProduct?.circularityIndex?.co2Reduction?.breakdown?.map(item => {
              const key = Object.keys(item.data)[0];
              const value = item.data[key as any];
              return {title: key, value: value.toFixed(2), percent: item.percent};
            }) || [],
        },
      },
    },
  };

  if (passportProduct?.images) {
    data.scrap.images.concat(passportProduct.images);
  }

  const product = data[id as keyof typeof data] || data.scrap;

  return (
    <View>
      {showTitle && (
        <Text color="coolGray.700" fontSize="2xl" fontWeight={700} mb={4}>
          {product.name}
        </Text>
      )}

      <Stack flex={1} direction={{base: 'column', lg: 'row'}} mb={25} space="lg">
        <View flex={1}>
          <ImageGallery images={product.images} />
        </View>
        <VStack flex={1} space="lg">
          <HStack flex={1} flexBasis="content" flexGrow={0} space={{base: 0, lg: 66}}>
            <VStack flex={1} space="xs">
              <Text textAlign="justify">{product.description}</Text>
              <TableBox title={t('Properties')!} isNotBoxed data={product.properties!} />
              {showSummarySheets && (
                <View pt={4} pb={2} pl={2}>
                  <LinkList isBoxed={false} direction="horizontal" items={product.summarySheets} />
                </View>
              )}
            </VStack>
            <View display={{base: 'none', lg: 'flex'}} flex="0 0 auto">
              <QRCode style={{height: '102px', width: '102px'}} value={product.url} viewBox="0 0 256 256" />
            </View>
          </HStack>
          <View>
            <Text color="coolGray.700" fontSize="xl" fontWeight="semibold" mb={3}>
              {t('Índice de Circularidad')}
            </Text>
            <Stack direction={['column', 'row']} space="xs">
              <VStack space="xs" flex={1}>
                <View>
                  <ComponentRadialbar
                    title={t('Material reciclado utilizado')!}
                    value={product.circularityIndex.recycledMaterialUsagePercent}
                    height="180px"
                    hollowSize="75%"
                    icon={<Icon as={AdalmoIcon} name="screw" size={5} color="primary.500" />}
                  />
                </View>
                <View>
                  <ComponentRadialbar
                    title={t('Valorización de residuos')!}
                    value={product.circularityIndex.wasteValorizationPercent}
                    height="180px"
                    hollowSize="75%"
                    icon={<Icon as={AdalmoIcon} name="recycle" size={5} color="primary.500" />}
                  />
                </View>
              </VStack>
              <VStack space="xs" flex={1}>
                <View>
                  <ComponentValue
                    title={t('Huella de carbono')!}
                    icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />}
                    data={product.circularityIndex?.carbonFootprint}
                  />
                </View>
                <View flex={1}>
                  <ComponentValuePercent
                    title={t('Ahorro CO2 por compra circular')!}
                    icon={<Icon as={AdalmoIcon} name="co2_leaf" size={5} color="primary.600" />}
                    data={{value: product.circularityIndex.co2Reduction.value, units: product.circularityIndex.co2Reduction.units}}
                    percentages={product.circularityIndex.co2Reduction.breakdown.map((breakdown: any, index: number) => ({
                      ...breakdown,
                      colorScheme: ['primary', 'danger'][index],
                    }))}
                  />
                </View>
              </VStack>
            </Stack>
          </View>
        </VStack>
      </Stack>

      {showSummarySheets && <SummarySheetModal id={summarySheetModalId} isOpen={isSummarySheetModalOpen} onClose={() => setIsSummarySheetModalOpen(false)} />}
    </View>
  );
};

export default ProductData;
