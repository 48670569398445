import {FC} from 'react';
import {ColorSchemeType} from 'native-base/lib/typescript/components/types';
import {Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox} from '../GenericBox';
import ComponentValue, {Props as ComponentValuePropsBase} from './ComponentValue';
import {ComponentPercentBar} from './ComponentPercentBar';

type Percent = {
  title: string;
  value: string;
  percent: number;
  colorScheme: ColorSchemeType;
};

type Props = Omit<ComponentValuePropsBase, 'data'> &
  Partial<Pick<ComponentValuePropsBase, 'data'>> & {
    percentages: Percent[];
    hasData?: boolean;
  };

const ComponentValuePercent: FC<Props> = ({data, hasData = true, percentages, ...genericBoxProps}) => {
  const {t} = useTranslation();

  if (!percentages || percentages.length === 0) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  return (
    <GenericBox {...genericBoxProps}>
      {hasData && (data?.value || data?.units) && (
        <View mb={3}>
          <ComponentValue isNotBoxed smallUnits data={{value: data?.value, units: data?.units}} />
        </View>
      )}
      {percentages.map(percentage => (
        <ComponentPercentBar isNotBoxed percentage={percentage} />
      ))}
    </GenericBox>
  );
};

export default ComponentValuePercent;
