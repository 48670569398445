import {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {PageLayout} from '../../components/PageLayout';
import TabBar from '../../components/TabBar';
import ProductData from '../../components/ProductData';
import {useProductPassportsService} from '../../services/ProductPassportsService';
import {Mapping} from '../../utils/fieldMapping';
import {ParsedProduct} from '../../types/types';

const PassportPage: FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const sharedToast = useSharedToast();
  const params = useParams();
  const productPassportsService = useProductPassportsService();
  const [loading, setLoading] = useState(true);
  const [passportProduct, setPassportProduct] = useState<Partial<ParsedProduct>>({});

  useEffect(() => {
    const productId = params.id;
    if (productId) {
      productPassportsService
        .get({id: productId})
        .response(resp => {
          setPassportProduct(Mapping(resp));
        })
        .error(() => {
          navigate('/');

          sharedToast({
            title: t('Algo ha ido mal'),
            description: t('No se ha podido obtener la información del pasaporte de producto seleccionado'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
            isClosable: true,
          });
        })
        .finally(() => setLoading(false));
    } else {
      console.debug(loading);
      // TODO redirect
    }
  }, []);

  if (loading) {
    return null;
  }

  return (
    // <PageLayout title={t('Pasaporte con id {{id}}', {id: params.id})}>
    // <PageLayout title={t('Pasaporte con id {{id}}', {id: passportProduct.gtin})}>
    <PageLayout title={t('{{id}}', {id: passportProduct.name})}>
      <ProductData passportProduct={passportProduct} id="" showTitle={false} showSummarySheets />
      <TabBar passportProduct={passportProduct} />
    </PageLayout>
  );
};
export default PassportPage;
