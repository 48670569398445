import {createBrowserRouter} from 'react-router-dom';
import {USER_ACTIONS} from '@bri/circularpass-adalmo-core';
import Root from './root';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import CookiesPage from '../pages/CookiesPage';
import CookiesConsentPage from '../pages/CookiesConsentPage';
import UnAuthenticated from '../components/UnAuthenticated';
import ProtectedRoute from '../pages/ProtectedRoute';
import PassportPage from '../pages/passportPages/PassportPage';
import PassportListPage from '../pages/passportPages/PassportListPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'login',
        element: (
          <UnAuthenticated>
            <LoginPage />
          </UnAuthenticated>
        ),
      },

      // Authorized
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.USE_APP} />,
        children: [
          {
            path: '',
            element: <PassportListPage />,
          },
          {
            path: 'passport/:id',
            element: <PassportPage />,
          },
        ],
      },
      // Generic Screens
      {
        path: 'termsAndConditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: 'privacyPolicy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'cookies',
        element: <CookiesPage />,
      },
      {
        path: 'cookies-config',
        element: <CookiesConsentPage />,
      },
      {
        path: '*',
        element: <ErrorPage code={404} message={"There's nothing here: 404"} />,
      },
    ],
  },
]);
