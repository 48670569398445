import {Image, Stack, useBreakpointValue, View} from 'native-base';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {getImageDimensions, getResource, Images} from '../../utils/imagesUtils';
import {ImageGalleryThumbnails} from './ImageGalleryThumbnails';
import {ImageGalleryStatus} from './ImageGalleryStatus';

type Props = {
  images: string[];
  showThumbnails?: boolean;
};

export const ImageGallery: FC<Props> = ({images, showThumbnails}) => {
  const [currentImage, setCurrentImage] = useState<string>(getResource(Images.imageTemp).uri);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState(0);

  const isLayoutVertical: boolean = useBreakpointValue({base: true, md: false});

  const handleImageClick = useCallback((image: string) => {
    setCurrentImage(image);
  }, []);

  const goToPreviousImage = useCallback(() => setCurrentImageIndex(Math.max(0, currentImageIndex - 1)), [currentImageIndex]);
  const goToNextImage = useCallback(() => setCurrentImageIndex(Math.min(images.length - 1, currentImageIndex + 1)), [currentImageIndex]);

  useEffect(() => {
    setCurrentImage(images[currentImageIndex]);
  }, [currentImageIndex]);

  useEffect(() => {
    setCurrentImage(images[currentImageIndex]);
  }, [images]);

  const getLargestImageHeight = async (): Promise<number> => {
    let largestHeight = 0;

    for (const image of images) {
      const {height} = await getImageDimensions(image);
      largestHeight = Math.max(largestHeight, height);
    }

    return largestHeight;
  };

  const adjustContainerHeight = async () => {
    if (!containerRef.current) {
      return;
    }

    const largestHeight = await getLargestImageHeight();

    if (!largestHeight) {
      return;
    }

    setMinHeight(largestHeight);
  };

  useEffect(() => {
    if (!minHeight) {
      adjustContainerHeight();
    }
  }, []);

  return (
    <Stack flex={1} direction={['column-reverse', 'column-reverse', 'row']} space={3} ref={containerRef}>
      {showThumbnails && (
        <Stack flex="0 0 auto">
          <ImageGalleryThumbnails images={images} isLayoutVertical={isLayoutVertical} handleThumbnailClick={handleImageClick} />
        </Stack>
      )}
      <View flex={1}>
        <Image source={{uri: currentImage}} borderRadius={10} height={minHeight} maxHeight={[250, 250, 250, 750]} resizeMode="cover" />
        {!showThumbnails && (
          <ImageGalleryStatus currentImageIndex={currentImageIndex} imageCount={images.length} goToPreviousImage={goToPreviousImage} goToNextImage={goToNextImage} />
        )}
      </View>
    </Stack>
  );
};

ImageGallery.defaultProps = {
  showThumbnails: true,
};
