import {HStack, Icon, IconButton, Text, View} from 'native-base';
import {FC} from 'react';
import {FontAwesome} from '@native-base/icons';

type Props = {
  currentImageIndex: number;
  imageCount: number;
  goToPreviousImage: () => void;
  goToNextImage: () => void;
};

export const ImageGalleryStatus: FC<Props> = ({currentImageIndex, imageCount, goToPreviousImage, goToNextImage}) => (
  <View mt={2}>
    <HStack justifyContent="space-between" alignItems="center">
      <IconButton icon={<Icon as={FontAwesome} name="angle-left" color="primary.600" size="2xl" />} onPress={goToPreviousImage} />
      <Text>
        {currentImageIndex + 1} / {imageCount}
      </Text>
      <IconButton icon={<Icon as={FontAwesome} name="angle-right" color="primary.600" size="2xl"
textAlign="right" />} onPress={goToNextImage} />
    </HStack>
  </View>
);
