import {Box, Icon, Stack, Text, View} from 'native-base';
import {FC} from 'react';
import {t} from 'i18next';
import ComponentValue from './graphs/ComponentValue';
import ComponentValuePercent from './graphs/ComponentValuePercent';
import TableBox from './TableBox';
import ComponentBarChart from './graphs/ComponentBarChart';
import AdalmoIcon from '../config/adalmo-font';
import {ImageGallery} from './image-gallery/ImageGallery';
import {ComponentPie} from './graphs/ComponentPie';
import CertificateList from './CertificateList';

export type Props = {
  data: {description: any; images: any[]; pie: any[]; value: any; percent: any; bar: any[]; tableBox: any};
};

const ComponentProcessDetail: FC<Props> = props => {
  if (!props.data) {
    return null;
  }

  return (
    <Box flex={1} backgroundColor="white" rounded="lg">
      <Stack flex={1}>
        <Stack flex={1 / 4} direction={['column', 'column', 'column', 'column', 'row']} space={10}>
          {/* Galeria */}
          <View flex={1 / 4}>
            <View flex={1 / 2}>
              {/* //! Actualment canvia de mesura segons el tamany de la imatge (no hauria de variar el tamany dels components) */}
              <ImageGallery images={props.data.images} showThumbnails={false} />
            </View>
          </View>
          {/**/}
          <Stack flex={3 / 4}>
            {/* Descripcion */}
            <Stack pb={[10, 10, 10, 10, 20]}>
              <Text fontSize="md" textAlign="left">
                {t(props.data.description)}
              </Text>
            </Stack>
            {/**/}
            <Stack space={2}>
              {/* aqui va el grafico y el componente value */}
              <Stack direction={['column', 'column', 'column', 'column', 'row']} space={[2]}>
                <Stack flex={0.6}>
                  <ComponentPie
                    title={t('Material de Origen')!}
                    description={t('Origen de los materiales usados')!}
                    icon={<Icon as={AdalmoIcon} name="screw" size={5} color="primary.600" />}
                    data={props.data.pie}
                    isBackgroundGray
                  />
                </Stack>
                <Stack direction="column" flex={0.4} space={[2]}>
                  <View>
                    <ComponentValue
                      title={t('Huella de carbono')!}
                      icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />}
                      data={props.data.value}
                      isBackgroundGray
                    />
                  </View>
                  <View>
                    <ComponentValuePercent
                      title={t('Ahorro CO2 por compra circular')!}
                      icon={<Icon as={AdalmoIcon} name="co2_leaf" size={5} color="primary.600" />}
                      data={props.data.percent.data}
                      percentages={props.data.percent.percentages}
                      isBackgroundGray
                    />
                  </View>
                </Stack>
              </Stack>
              {/* aqui va la barra y la tabla */}
              <Stack direction={['column', 'column', 'column', 'column', 'row']} space={2}>
                <Stack flex={2 / 4} space={[2]}>
                  <ComponentBarChart
                    title={t('Residuos generados en la producción')!}
                    description={t('Residuos generados durante la fabricación de los productos') as string}
                    icon={<Icon as={AdalmoIcon} name="bag" size={5} color="primary.600" />}
                    data={props.data.bar}
                    isBackgroundGray
                  />
                </Stack>
                <Stack flex={2 / 4} space={[2]}>
                  <TableBox
                    title={t('Energía usada en la producción')!}
                    icon={<Icon as={AdalmoIcon} name="bolt" size={5} color="primary.600" />}
                    data={props.data.tableBox.data}
                    footer={<CertificateList certificates={props.data.tableBox.certificates} />}
                    isBackgroundGray
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ComponentProcessDetail;
