import {Cookies} from '@bri/shared-components';
import {PageLayout} from '../components/PageLayout';

const CookiesPage = () => (
  <PageLayout>
    <Cookies
      titleFontSize="2xl"
      company={{
        name: 'Blue Room Innovation SL.',
        address: 'Gran Vía Carles III, 98 08028, Barcelona',
        contactEmail: 'info@blueroominnovation.com',
        id: 'B55237689',
      }}
      projectName="CircularPass"
      appOrUrl="CircularPass"
    />
  </PageLayout>
);

export default CookiesPage;
