import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, Stack, VStack, View} from 'native-base';
import AdalmoIcon from '../../config/adalmo-font.js';
import ComponentValuePercent from '../../components/graphs/ComponentValuePercent';
import Header from '../../components/Header';
import TableBox from '../../components/TableBox';
import CertificateList from '../../components/CertificateList';
import ComponentPieSidebarData from '../../components/graphs/ComponentPieSidebarData';
import MapLocation from '../../components/MapLocation';
import {GenericBox} from '../../components/GenericBox';
import DropdownComponent from '../../components/DropdownComponent';
import {ParsedProduct} from '../../types/types';
import CircularTrustDid from '../../components/CircularTrustDid';
import {CurrentTheme} from '../../theme';

type Props = {
  passportProduct: Partial<ParsedProduct>;
};

const InformationPage: FC<Props> = ({passportProduct}) => {
  const {t} = useTranslation();
  const informationPassport = passportProduct?.information;

  const data = {
    origin: {
      coordinates: {
        latitude: informationPassport?.origin?.coordinates?.latitude ? informationPassport?.origin?.coordinates?.latitude : 0,
        longitude: informationPassport?.origin?.coordinates?.longitude ? informationPassport?.origin?.coordinates?.longitude : 0,
      },
    },
    route: passportProduct?.distribution?.traceabilitySections?.map(r => ({title: r?.Origin?.name || '', image: r?.organizationOrigin?.logo || ''})) || [],
    trackedMaterialPercent: informationPassport?.trackedMaterialPercent || 0,
    carbonFootprintComparisons: informationPassport?.carbonFootprintComparisons || [],
    properties: {
      data: informationPassport?.properties?.data?.map(item => {
        const key = Object.keys(item)[0];
        return {label: key, value: item[key]};
      }),
      certificates: (informationPassport?.properties?.certificates || []).map(item => ({name: item.label, link: item.link})),
    } || {data: [], certificates: []},
  };

  let lastPlace;
  if (passportProduct?.distribution?.traceabilitySections) {
    /* eslint no-unsafe-optional-chaining: "error" */
    lastPlace = passportProduct?.distribution?.traceabilitySections[passportProduct?.distribution?.traceabilitySections?.length - 1] || undefined;
  }
  if (lastPlace) {
    data.route.push({title: lastPlace?.organizationDestiny?.name || '', image: lastPlace?.organizationDestiny?.image || ''}); // eslint fp/no-mutating-methods: ["error", {"allowedObjects": ["_"]}]
  }

  return (
    <View py={6}>
      <Header imageSource={CurrentTheme.images.information} text={t('Información')} icon={<Icon as={AdalmoIcon} name="info-circle" size={5} color="white" />} />
      <Stack direction={['column', 'column', 'row', 'row', 'row']} mt={6} space="xs">
        <VStack flex={['0 0 auto', '0 0 auto', 3 / 5, 3 / 5, 3 / 5]} space="xs">
          <View>
            <MapLocation title={t('Origen')} zoom={7} coordinates={{lat: data?.origin?.coordinates?.latitude || 0, lng: data?.origin?.coordinates?.longitude || 0}} />
          </View>
          <View>
            <GenericBox title={t('Recorrido')!} overflowVisible>
              <DropdownComponent title={t('Recorrido')!} sections={data?.route} dropdownId="information" sectionTitleBackgroundColor="gray.100" />
            </GenericBox>
          </View>
        </VStack>
        <VStack flex={2 / 5} space="xs">
          <View>
            <ComponentPieSidebarData
              title={t('Material trazado')}
              percent={data?.trackedMaterialPercent}
              description={t('Porcentaje de material trazado por balance de masas')}
            />
          </View>
          <View>
            <ComponentValuePercent
              title={t('Comparativa huella de carbono')!}
              hasData={false}
              percentages={data?.carbonFootprintComparisons?.map(comparison => ({...comparison, colorScheme: 'primary'}))}
            />
          </View>
          <View>
            <TableBox title={t('Características')!} data={data?.properties?.data || []} footer={<CertificateList certificates={data?.properties?.certificates} />} />
          </View>
          <View>
            <CircularTrustDid contract="0x80d5a4452083848c45ec4a99e6a3e300921eb39b" productGtin={passportProduct.gtin} />
          </View>
        </VStack>
      </Stack>
    </View>
  );
};

export default InformationPage;
