import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import {extendTheme, NativeBaseProvider, theme as baseTheme} from 'native-base';
import fontsCSS from '@native-base/icons/FontsCSS';
import {StorageService} from '@bri/shared-components';
import './index.css';
import '@bri/shared-components/dist/index.css';
import App from './App';
import {CurrentTheme} from './theme';

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(fontsCSS));
document.head.appendChild(style);

StorageService.setStorage(localStorage);

const theme = extendTheme(
  {
    fontConfig: {
      Avenir: {
        100: {
          normal: 'Avenir-Light',
        },
        200: {
          normal: 'Avenir-Light',
        },
        300: {
          normal: 'Avenir-Regular',
        },
        400: {
          normal: 'Avenir-Regular',
        },
        500: {
          normal: 'Avenir-Regular',
        },
        600: {
          normal: 'Avenir-Regular',
        },
        700: {
          normal: 'Avenir-Black',
        },
      },
    },
    fonts: {
      heading: 'Avenir',
      body: 'Avenir',
      mono: 'Avenir',
      custom: 'Avenir',
    },
    fontSizes: {
      '2xs': 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 22,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
      '7xl': 72,
      '8xl': 96,
      '9xl': 128,
    },
    colors: {
      primary: {
        50: '#E8F3F8',
        100: '#CBE3F0',
        200: '#AED3E8',
        300: '#91C3E0',
        400: '#74B3D8',
        500: '#0284C7',
        600: '#0069A3',
        700: '#00517F',
        800: '#00395B',
        900: '#002137',
      },
      secondary: {
        50: '#F7FCFE',
        100: '#E4F4FD',
        200: '#D1ECFC',
        300: '#BEDEFB',
        400: '#A6CDF9',
        500: '#C3E3F6',
        600: '#89B0EF',
        700: '#7592E6',
        800: '#6174DD',
        900: '#4D56D4',
      },
      white: '#FFFFFF',
    },
    components: {
      Button: {
        // Can simply pass default props to change default behaviour of components.
        baseStyle: (props: any) => {
          if (props.variant === 'outline') {
            return {
              rounded: 'full',
              borderBottomColor: `${props.colorScheme}.500`,
              borderLeftColor: `${props.colorScheme}.500`,
              borderRightColor: `${props.colorScheme}.500`,
              borderTopColor: `${props.colorScheme}.500`,
            };
          }
          if (props.variant === 'login') {
            return {
              bg: 'primary.500',
              borderRadius: 7,
              width: '100%',
              _hover: {
                bg: `primary.600`,
              },
              _pressed: {
                bg: `primary.700`,
              },
              _text: {
                color: `primary.50`,
              },
              borderBottomColor: 'primary.500',
            };
          }
        },
      },
      Input: {
        baseStyle: {
          backgroundColor: 'secondary.500',
          borderRadius: 7,
          borderWidth: 0,
        },
        defaultProps: {
          size: 'md',
        },
        // variants: {
        //   login: () => ({
        //     borderWidth: 1,
        //   }),
        // },
      },
      Text: {
        baseStyle: {
          color: baseTheme.colors.coolGray[700],
        },
      },
    },
    config: {
      initialColorMode: 'light',
    },
  } && {...CurrentTheme?.theme}
);

// extend the theme
type MyThemeType = typeof theme;
declare module 'native-base' {
  type ICustomTheme = MyThemeType;
}

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
