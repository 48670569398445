import {AddIcon, Button, FormControl, HStack, Input, Modal, Text} from 'native-base';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useState} from 'react';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {useProductPassportsService} from '../../services/ProductPassportsService';

type Props = {
  showModal?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

const UrlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ModalAddUrl = (props: Props) => {
  const {t} = useTranslation();
  const sharedToast = useSharedToast();
  const productPassportService = useProductPassportsService();
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        url: Yup.string()
          .matches(UrlRegExp, t('Invalid URL schema') as string)
          .required(t('Required') as Yup.Message),
      })
    ),
  });

  // Called after Yup validation
  const onSubmit = async (data: any) => {
    setLoading(true);

    // Await to recieve server response
    productPassportService
      .addRemote(data)
      .response(res => {
        sharedToast({
          title: t('Remote product passport added successfully'),
          description: t(''),
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
        props.onSuccess?.();
        setLoading(false);
      })
      .error(err => {
        sharedToast({
          title: t('Error adding remote product passport'),
          description: t(''),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={props.showModal} onClose={props.onClose} size="lg">
      <Modal.Content maxWidth="350">
        <Modal.CloseButton />
        <Modal.Header>{t('Add file') as string}</Modal.Header>
        <Modal.Body>
          <Text pb={2}>{t('File URL:')}</Text>

          <FormControl isRequired isInvalid={'url' in errors} key="url" flex={1}>
            <Controller name="url" control={control} render={({field}) => <Input minW={10} placeholder="Ex: https://..." {...field} />} />
            {errors.url && <FormControl.ErrorMessage>{errors.url?.message}</FormControl.ErrorMessage>}
          </FormControl>

          <HStack justifyContent="center" space={4} pt={8}>
            <Button variant="outline" rounded="lg" onPress={props.onClose}>
              {t('Cancel')}
            </Button>
            <Button isLoading={loading} isLoadingText={t('Adding...') as string} leftIcon={<AddIcon />} rounded="lg"
onPress={handleSubmit(onSubmit)}>
              {t('Add')}
            </Button>
          </HStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
