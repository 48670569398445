import React, {FC, useEffect, useState} from 'react';
import {Icon, Stack, Text, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {TOAST_STATUS, TOAST_VARIANT} from '@bri/shared-components';
import {IEPCISEventExtended} from '@bri/circularpass-adalmo-core';
import AdalmoIcon from '../../config/adalmo-font';
import Header from '../../components/Header';
import {ImageGallery} from '../../components/image-gallery/ImageGallery';
import ComponentValue from '../../components/graphs/ComponentValue';
import ComponentValuePercent from '../../components/graphs/ComponentValuePercent';
import DropdownComponent from '../../components/DropdownComponent';
import ComponentProcessDetail from '../../components/ComponentProcessDetail';
import {ComponentPie} from '../../components/graphs/ComponentPie';
import ComponentBarChart from '../../components/graphs/ComponentBarChart';
import {CurrentTheme} from '../../theme';
import {ParsedProduct} from '../../types/types';
import {maxDecimals} from '../../utils/maxDecimals';
import {useProductPassportsService} from '../../services/ProductPassportsService';
import {Images, getResource} from '../../utils/imagesUtils';

type Props = {
  passportProduct: Partial<ParsedProduct>;
};

const ProductionPage: FC<Props> = ({passportProduct}) => {
  const {t} = useTranslation();
  const productPassportService = useProductPassportsService();
  const [product] = useState<ParsedProduct>(passportProduct);
  const [events, setEvents] = useState<Partial<IEPCISEventExtended[]>>([]);
  const [images, setImages] = useState<string[]>([getResource(Images.imageTemp).uri]);

  useEffect(() => {
    if (!product.id) {
      return;
    }
    productPassportService
      .getEvents({idPassport: product.id})
      .response(response => {
        setEvents(response as IEPCISEventExtended[]);
      })
      .error(() => {
        sharedToast({
          title: t('Algo ha ido mal'),
          description: t('No se ha podido obtener la información produccion del pasaporte'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
          isClosable: true,
        });
      });
  }, [product]);

  useEffect(() => {
    const eventsParsed =
      events?.map(event => ({
        title: event?.eventType || '',
        imageGroup: event?.imageGroup || undefined,
        dataProcesses: {
          description: event?.description || '',
          images: event?.images || [],
          pie: event?.materialOrigin?.map(item => ({label: item?.label || '', value: maxDecimals(item?.percentage, 2) || 0})) || [],
          value: {value: event?.carbonFootprint?.value || 0, units: event?.carbonFootprint?.unitCode || ''},
          percent: {
            data: {
              value: event?.co2savingsByCircularPurchase?.savings?.value?.toFixed(2) || 0 || undefined,
              units: event?.co2savingsByCircularPurchase?.savings?.unitCode || '',
            },
            percentages:
              event?.co2savingsByCircularPurchase?.breakdown?.map((item, index: number) => ({
                title: Object.keys(item.data)[0] || '',
                value: Object.values(item.data)[0] || 0,
                percent: item?.percent || 0,
                colorScheme: index === 0 ? 'primary' : 'danger',
              })) || [],
          },
          bar: event?.productionWaste?.map(item => ({label: item?.label || '', value: maxDecimals(item?.percentage, 2) || 0})) || [],
          tableBox: {
            data: event?.productionEnergy?.map((item: any) => ({label: item?.label || '', value: `${item?.value?.toString()} ${item?.units}` || ''})) || [],
            certificates: event?.certificates?.map(item => ({name: item.certificationStandard.string, link: item.certificationURI.value})) || [],
          },
        },
      })) || [];

    const images2 = eventsParsed.reduce((accumulator: any, ds) => {
      const imagesParse = ds.dataProcesses.images;
      if (!accumulator) {
        accumulator = []; // eslint-disable-line no-param-reassign
      }
      accumulator = accumulator.concat(imagesParse); // eslint-disable-line no-param-reassign
      return accumulator;
    }, []);
    setImages(images2 || [getResource(Images.imageTemp).uri]);
  }, [events]);

  const data = {
    dataSection:
      events?.map(event => ({
        title: event?.eventType || '',
        imageGroup: event?.imageGroup || undefined,
        dataProcesses: {
          description: event?.description || '',
          images: event?.images || [],
          pie: event?.materialOrigin?.map(item => ({label: item?.label || '', value: maxDecimals(item?.percentage, 2) || 0})) || [],
          value: {value: event?.carbonFootprint?.value || 0, units: event?.carbonFootprint?.unitCode || ''},
          percent: {
            data: {
              value: event?.co2savingsByCircularPurchase?.savings?.value?.toFixed(2) || 0 || undefined,
              units: event?.co2savingsByCircularPurchase?.savings?.unitCode || '',
            },
            percentages:
              event?.co2savingsByCircularPurchase?.breakdown?.map((item, index: number) => ({
                title: Object.keys(item.data)[0] || '',
                value: Object.values(item.data)[0] || 0,
                percent: item?.percent || 0,
                colorScheme: index === 0 ? 'primary' : 'danger',
              })) || [],
          },
          bar: event?.productionWaste?.map(item => ({label: item?.label || '', value: maxDecimals(item?.percentage, 2) || 0})) || [],
          tableBox: {
            data: event?.productionEnergy?.map((item: any) => ({label: item?.label || '', value: `${item?.value?.toString()} ${item?.units}` || ''})) || [],
            certificates: event?.certificates?.map(item => ({name: item.certificationStandard.string, link: item.certificationURI.value})) || [],
          },
        },
      })) || [],
  };

  const valuesWasteGraphic = data.dataSection.reduce((accumulator: any, ds) => {
    const dades = ds?.dataProcesses.bar || [];
    dades.forEach((element, index) => {
      if (!accumulator[index]) {
        accumulator[index] = {label: element.label, value: 0};
      }
      accumulator[index].value += maxDecimals(element.value, 2);
    });
    return accumulator;
  }, []);

  valuesWasteGraphic.forEach((element: any) => {
    /* eslint no-param-reassign: "error" */
    element.value = maxDecimals(element.value / data.dataSection.length, 2);
  });

  const valuesOriginGraphic = data.dataSection.reduce((accumulator: any, ds) => {
    const dades = ds?.dataProcesses.pie || [];
    dades.forEach((element, index) => {
      if (!accumulator[index]) {
        accumulator[index] = {label: element.label, value: 0};
      }
      accumulator[index].value += maxDecimals(element.value, 2);
    });
    return accumulator;
  }, []);

  valuesOriginGraphic.forEach((element: any) => {
    /* eslint no-param-reassign: "error" */
    element.value = maxDecimals(element.value / data.dataSection.length, 2);
  });

  const valuesCarbonFootprint = data.dataSection.reduce((accumulator: any, ds) => {
    const dades = ds?.dataProcesses.value || [];
    if (!accumulator[0]) {
      accumulator[0] = {units: dades.units, value: 0};
    }
    /* eslint no-param-reassign: "error" */
    accumulator[0].value += maxDecimals(dades.value, 2);
    return accumulator;
  }, []);

  const co2savingsByCircularPurchase = {
    data: {
      value: 0,
      units: 'kgCO2e',
    },
    percentages: data.dataSection.reduce((accumulator: any, ds) => {
      const percent = ds?.dataProcesses.percent.percentages || [];
      percent.forEach((element, index) => {
        if (!accumulator[index]) {
          accumulator[index] = {label: element.title, value: 0, percent: element.percent, colorScheme: element.colorScheme};
        }
        /* eslint no-param-reassign: "error" */
        accumulator[index].value += maxDecimals(element.value, 2);
      });
      return accumulator;
    }, []),
  };

  const sectionData = data.dataSection.map(section => ({
    title: section.title || 'Title',
    image: section.imageGroup,
    content: <ComponentProcessDetail data={section.dataProcesses} />,
  }));

  return (
    <VStack flex={1} py={6}>
      <Header imageSource={CurrentTheme.images.production} text={t('Producción')} icon={<Icon as={AdalmoIcon} name="barrow" size={5} color="white" />} />
      <VStack flex={1} justifyContent="flex-start">
        <Stack direction={['column', 'column', 'row']} space={10} mt={5}>
          <View flex={['0 0 auto', '0 0 auto', 1]}>
            <VStack flex={[1, 1, 4 / 5]}>
              <ImageGallery images={images} />
            </VStack>
          </View>
          <View flex={[1, 1, 1]}>
            <Stack direction="column">
              <Stack display="flex" flexWrap="wrap" direction="row" justifyContent="space-between" space={2}>
                <Text fontSize="xl" fontWeight="bold">
                  {t('Índice de Sostenibilidad')}
                </Text>
                {/* <DropdownFilter label={t('Filtrar por scopes:')} currentFilter={t('Todos')} /> */}
              </Stack>
              <Stack m={1}>
                <ComponentBarChart
                  title={t('Energia usada en la producción')!}
                  icon={<Icon as={AdalmoIcon} name="screw" size={5} color="primary.600" />}
                  data={[]} // TODO AGAFAR
                />
              </Stack>
              <Stack direction={['column', 'column', 'column', 'column', 'row']}>
                <Stack flex={1 / 2} m={1}>
                  <ComponentPie
                    defaultVertical
                    title={t('Material de Origen')!}
                    description={t('Origen de los materiales usados')!}
                    icon={<Icon as={AdalmoIcon} name="screw" size={5} color="primary.600" />}
                    data={valuesOriginGraphic || []}
                  />
                </Stack>
                <Stack direction="column" height="100%" flex={1 / 2} m={1}>
                  <Stack mb={1}>
                    <View height="100%">
                      <ComponentValue
                        title={t('Huella de carbono')!}
                        icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />}
                        data={valuesCarbonFootprint[0] || []}
                      />
                    </View>
                  </Stack>
                  <Stack mt={1}>
                    <View height="100%">
                      <ComponentValuePercent
                        title={t('Ahorro CO2 por compra circular')!}
                        icon={<Icon as={AdalmoIcon} name="co2_leaf" size={5} color="primary.600" />}
                        data={co2savingsByCircularPurchase.data}
                        percentages={co2savingsByCircularPurchase.percentages}
                      />
                    </View>
                  </Stack>
                </Stack>
              </Stack>
              <Stack m={1}>
                <ComponentBarChart
                  title={t('Residuos generados en la producción')!}
                  description={t('Residuos generados durante la fabricación de los productos')!}
                  icon={<Icon as={AdalmoIcon} name="bag" size={5} color="primary.600" />}
                  data={valuesWasteGraphic || []}
                />
              </Stack>
            </Stack>
          </View>
        </Stack>
        <VStack space={10} ml={1} mt={8}>
          {/* //TODO Dropdown component click */}
          <Stack direction="column">
            <Text fontSize="2xl" fontWeight="bold">
              {t('Procesos de producción')!}
            </Text>
            <Text fontSize="md">{t('Clica en cada proceso para ver los detalles, vuelve a clicar en el nombre del proceso para esconder los detalles')!}</Text>
          </Stack>
          <View pl={4}>
            <DropdownComponent title={t('Procesos de producción')!} sections={sectionData || []} dropdownId="production" />
          </View>
        </VStack>
      </VStack>
    </VStack>
  );
};
export default ProductionPage;
function sharedToast(arg0: {title: string; description: string; status: TOAST_STATUS; variant: TOAST_VARIANT; isClosable: boolean}) {
  throw new Error('Function not implemented.');
}
