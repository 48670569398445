import {Icon, Stack, Text, useBreakpointValue, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {FC, useEffect, useState} from 'react';
import Xarrow, {refType, useXarrow} from 'react-xarrows';
import Header from '../../components/Header';
import TableBox from '../../components/TableBox';
import ComponentValue from '../../components/graphs/ComponentValue';
import ComponentValuePercent from '../../components/graphs/ComponentValuePercent';
import {TraceabilityGrid} from '../../components/TraceabilityGrid';
import AdalmoIcon from '../../config/adalmo-font.js';
import {Map} from '../../components/MapFilter';
import {ComponentPie} from '../../components/graphs/ComponentPie';
import {CurrentTheme} from '../../theme';
import {TraceabilitySection, ParsedProduct} from '../../types/types';
import SummarySheetModal from '../../components/SummaryDistributionTraceabilityModal';
import {getMapIconForProperty} from '../../utils/iconsMap';
import {maxDecimals} from '../../utils/maxDecimals';

type Props = {
  passportProduct: Partial<ParsedProduct>;
};

const DistributionPage: FC<Props> = ({passportProduct}) => {
  const {t} = useTranslation();
  const isTableBoxVertical = useBreakpointValue({base: true, lg: false});
  const isGraphicsVertical = useBreakpointValue({base: true, md: false});
  const passportDistribution = passportProduct?.distribution;

  const [summarySheetModalData, setsummarySheetModalData] = useState<{}>({});
  const [summarySheetModalTitle, setsummarySheetModalTitle] = useState<string>('');
  const [isSummarySheetModalOpen, setIsSummarySheetModalOpen] = useState<boolean>(false);

  const openModal = (data: any, title: string) => {
    setsummarySheetModalData(data);
    setsummarySheetModalTitle(title);
    setIsSummarySheetModalOpen(true);
  };

  const data = {
    traceabilitySections:
      passportDistribution?.traceabilitySections?.map((section: TraceabilitySection) => ({
        co2Generated: section?.co2Generated?.toFixed(2) || 0,
        description: section?.description,
        ierCode: section?.ierCode,
        dangerProperties: section?.dangerProperties,
        treatment: section?.treatment,
        weight: section?.weight,
        eventTime: section?.eventTime,
        distance: section?.distance,
        origin: section?.Origin,
        destiny: section?.Destiny,
        transport: {
          type: getMapIconForProperty(section?.transport?.type || ''),
          shipper: section?.transport?.shipper,
          vehicle: {
            id: section?.transport?.vehicle?.id,
            propulsion: section?.transport?.vehicle?.propulsion || '',
            weight: section?.transport?.vehicle?.weight,
          },
        },
        route: section?.route?.coordinates?.map(c => ({lat: c[1], lng: c[0]} || null)),
        co2savingsByCircularPurchase: {
          value: section.co2savingsByCircularPurchase?.savings?.value?.toFixed(2) || 0 || 0,
          units: 'Co2',
          breakdown:
            section.co2savingsByCircularPurchase?.breakdown?.map(item => {
              const key = Object.keys(item.data)[0];
              const value = item.data[key as any];
              return {title: key, value: value?.toFixed(2) || 0, percent: item.percent};
            }) || [],
        },
      })) || [],
    dataGraphicCo2: {
      value: maxDecimals(passportDistribution?.circularityIndex?.carbonFootprint?.value || 0, 2),
      units: passportDistribution?.circularityIndex?.carbonFootprint?.unitCode,
    } || {value: 0, units: 'NaN'},
    co2savingsByCircularPurchase: {
      value: maxDecimals(passportDistribution?.circularityIndex?.co2savingsByCircularPurchase?.savings?.value || 0, 2),
      units: 'Co2',
      breakdown:
        passportDistribution?.circularityIndex?.co2savingsByCircularPurchase?.breakdown?.map(item => {
          const key = Object.keys(item.data)[0];
          const value = item.data[key as any];
          return {title: key, value: value?.toFixed(2) || 0, percent: item?.percent};
        }) || [],
    },
    carbonFootprintTnKm: passportDistribution?.circularityIndex?.carbonFootprintTnKm || [],
    transportTypes: passportDistribution?.transportTypes?.map(a => ({label: a.label, value: a.percentage})) || [],
    transportRenewableEnergy: passportDistribution?.transportRenewableEnergy?.map(a => ({label: a.label, value: a.percentage})) || [],
  };

  const arrowsMap = data.traceabilitySections.map(s => ({
    filter: s.transport.type,
    route: (s?.route || []).map(a => ({lat: a.lat, lng: a.lng})),
  }));

  const locationsMap: {lat: number; lng: number; image: string}[] = [];
  data.traceabilitySections.forEach(s => {
    const route: {lat: number; lng: number}[] = s?.route || [{lat: 0, lng: 0}];
    locationsMap.push({lat: route[0].lat || 0, lng: route[0].lng, image: s?.origin?.image || ''});
    locationsMap.push({lat: route[route.length - 1]?.lat, lng: route[route.length - 1].lng, image: s?.destiny?.image || ''});
  });

  const valuesTraceability = data.traceabilitySections.map((ts, index: number) => ({
    icon: ts.transport.type,
    title: t('Tramo {{index}}', {index: index + 1}),
    table: [
      {label: t('Descripción'), value: ts.description},
      {label: t('Kilómetros'), value: ts.distance},
      {label: t('Tipo transporte'), value: ts.transport.type},
      {label: t('Origen'), value: ts?.origin?.name},
      {label: t('Destino'), value: ts?.destiny?.name},
      {label: t('CO2 generado'), value: ts.co2Generated},
    ],
    data: ts,
  }));

  const tableBoxData = valuesTraceability.map((traceabilityData: any, index: number) => ({
    key: `table${index + 1}`,
    title: t('Tramo {{index}}', {index: index + 1}),
    icon: <Icon as={AdalmoIcon} name={traceabilityData.icon} size={5} color="primary.600" />,
    data: traceabilityData.table,
    footer: (
      <Text color="primary.600" fontWeight="bold" fontSize="md" onPress={() => openModal(traceabilityData.data, t('Tramo {{index}}', {index: index + 1}))}>
        {t('Ver información')}
      </Text>
    ),
  }));

  const XarrowsMap: FC<{tableBox: {key: string}[]}> = ({tableBox: dataBox}) => (
    <View>
      {dataBox.map((item, index) => {
        const nextItem = dataBox[index + 1];
        const isIndexMultipleOfThree = (index + 1) % 3 === 0;

        const startAnchor = isTableBoxVertical || isIndexMultipleOfThree ? 'bottom' : 'right';
        const endAnchor = isTableBoxVertical || isIndexMultipleOfThree ? 'top' : 'left';

        if (index + 1 < dataBox.length && nextItem) {
          return (
            <Xarrow
              key={`xarrow-${item.key}-${nextItem.key}`}
              start={item.key as refType}
              end={nextItem.key as refType}
              startAnchor={startAnchor}
              endAnchor={endAnchor}
              path="grid"
              color="#CCCAD8"
              headSize={3.5}
              showHead={isTableBoxVertical || index + 1 === dataBox.length - 1}
            />
          );
        }
        return null;
      })}
    </View>
  );

  const updateXarrows = useXarrow();

  useEffect(() => {
    window.addEventListener('resize', updateXarrows);
    return () => {
      window.removeEventListener('resize', updateXarrows);
    };
  }, []);

  return (
    <View py={6}>
      <Header imageSource={CurrentTheme.images.distribution} text={t('Distribución')} icon={<Icon as={AdalmoIcon} name="truck" size={5} color="white" />} />
      <Stack direction={['column', 'column', 'column', 'row', 'row']} space={2} marginTop={6} marginBottom={2}>
        <View flex={['0 0 auto', '0 0 auto', '0 0 auto', 3 / 4, 3 / 4, 3 / 4]}>
          <Map
            title={t('Trazabilidad del Producto')}
            description={t('Recorrido del producto en sus distintas fases de diseño y producción')!}
            icon={<Icon as={AdalmoIcon} name="compass" size={5} color="primary.600" />}
            showFilter
            locations={locationsMap}
            arrows={arrowsMap}
          />
        </View>
        <Stack flex={[1, 1, 1, 1 / 3, 1 / 3]} space={2}>
          <View fontSize="md">
            <ComponentValue title={t('Huella de carbono')!} icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />} data={data.dataGraphicCo2} />
          </View>
          <View>
            <ComponentValuePercent
              title={t('Ahorro CO2 por compra circular')!}
              icon={<Icon as={AdalmoIcon} name="co2_leaf" size={5} color="primary.600" />}
              data={{value: data.co2savingsByCircularPurchase.value, units: data.co2savingsByCircularPurchase.units}}
              percentages={data.co2savingsByCircularPurchase.breakdown.map((breakdown: any, index: number) => ({
                ...breakdown,
                colorScheme: ['primary', 'danger'][index],
              }))}
            />
          </View>
          <Stack>
            <TableBox
              title={t('Huella de carbono por Tn-Km (g/Tkm)')!}
              icon={<Icon as={AdalmoIcon} name="co2_cloud" size={5} color="primary.600" />}
              data={data.carbonFootprintTnKm}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={['column', 'column', 'column', 'column', 'row', 'row']} marginBottom={10} space={2}>
        <View flex={[undefined, undefined, undefined, undefined, 1 / 2, 1 / 2]}>
          <ComponentPie
            title={t('Tipo de transporte')!}
            icon={<Icon as={AdalmoIcon} name="truck" size={5} color="primary.600" />}
            data={data.transportTypes}
            defaultVertical={isGraphicsVertical}
          />
        </View>
        <View flex={[undefined, undefined, undefined, undefined, 1 / 2, 1 / 2]}>
          <ComponentPie
            title={t('Energía usada en la distribución')!}
            icon={<Icon as={AdalmoIcon} name="bolt" size={5} color="primary.600" />}
            data={data.transportRenewableEnergy}
            defaultVertical={isGraphicsVertical}
          />
        </View>
      </Stack>

      <View marginBottom={8}>
        <Text color="coolGray.700" fontSize="lg" fontWeight="bold">
          {t('Trazabilidad resumida por tramos')}
        </Text>
        <Text>{t('Clica en cada tramo para ver detalles')}</Text>
      </View>

      <TraceabilityGrid
        sections={tableBoxData.map((item: any) => (
          <View nativeID={item.key}>
            <TableBox title={item.title} icon={item.icon} data={item.data} footer={item.footer} />
          </View>
        ))}
      />
      <XarrowsMap tableBox={tableBoxData} />
      {isSummarySheetModalOpen && (
        <SummarySheetModal title={summarySheetModalTitle} data={summarySheetModalData} isOpen={isSummarySheetModalOpen} onClose={() => setIsSummarySheetModalOpen(false)} />
      )}
    </View>
  );
};

export default DistributionPage;
