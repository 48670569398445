import React, {useState, useEffect} from 'react';
import {View, Text, StyleSheet, ImageBackground, Dimensions} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {getResource} from '../utils/imagesUtils';

interface Props {
  imageSource: string;
  icon: JSX.Element;
  text: string;
}

const FROM_COLOR = 'rgba(0, 0, 0, 0)';
const TO_COLOR = 'rgba(0, 0, 0, 1)';

const Header: React.FC<Props> = ({imageSource, icon, text}) => {
  const [, setWindowWidth] = useState(Dimensions.get('window').width);
  const [windowHeight, setWindowHeight] = useState(Dimensions.get('window').height);
  const [borderRadius, setBorderRadius] = useState(10);

  const handleLayout = () => {
    const {width, height} = Dimensions.get('window');
    setWindowWidth(width);
    setWindowHeight(height);

    if (width <= 600) {
      setBorderRadius(0);
    } else {
      setBorderRadius(10);
    }
  };

  useEffect(() => {
    Dimensions.addEventListener('change', handleLayout);
    return () => {
      Dimensions.removeEventListener('change', handleLayout);
    };
  }, []);

  return (
    <View style={[styles.container, {borderRadius, height: windowHeight / 3}]} onLayout={handleLayout}>
      <ImageBackground source={getResource(imageSource)} style={styles.image} resizeMode="cover">
        <LinearGradient colors={[FROM_COLOR, TO_COLOR]} style={styles.gradient} />
      </ImageBackground>
      <View style={styles.overlay} />
      <View style={styles.textContainer}>
        {icon}
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 200,
    maxHeight: 250,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 40,
  },
  icon: {
    width: 25,
    height: 20,
    marginRight: 4,
  },
  text: {
    fontSize: 30,
    color: '#fff',
    marginLeft: 15,
    marginBottom: 3,
  },
});

export default Header;
