import {FC} from 'react';
import {MyNewMapComponent} from '@bri/shared-components';
import {useBreakpointValue, Text, useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox} from './GenericBox';
import Config from '../config/config';
import markerImage from '../images/map-location-marker.png';

type Props = {
  title: string;
  description?: string;
  zoom?: number;
  coordinates: {
    lat: number;
    lng: number;
  };
};

const markerDimensions = {width: 35, height: 40};

const MapLocation: FC<Props> = ({title, description, coordinates, zoom}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const mapHeight: number = useBreakpointValue({base: 230, md: 479});

  if (coordinates.lat === 0 || coordinates.lng === 0) {
    return (
      <GenericBox title={title}>
        <Text>{t(`No se ha encontrado ${title}`)}</Text>
      </GenericBox>
    );
  }

  return (
    <GenericBox title={title} description={description}>
      <MyNewMapComponent
        apikey={Config.googleMaps.apiKey}
        zoom={zoom}
        center={{coords: coordinates}}
        containerStyle={{minHeight: `${mapHeight}px`, borderRadius: '10px'}}
        iconsHidden
        locations={[
          {
            coords: coordinates,
            iconImage: {
              url: markerImage,
              anchor: {x: markerDimensions.width / 2, y: markerDimensions.height} as google.maps.Point,
              scaledSize: {width: markerDimensions.width, height: markerDimensions.height} as google.maps.Size,
            },
          },
          {
            coords: coordinates,
            icon: {
              symbol: {
                path:
                  'm 28.252115,20.096021 c -0.742992,1.530296 -1.785443,2.859603 -3.030185,4.051602 -2.953122,2.828559 -5.907081,5.656719 -8.860622,8.48488 -0.171717,0.164373 -0.353068,0.318795 -0.56583,0.433816 -0.820893,0.444562 -1.658539,0.335113 -2.370957,-0.336705 C 11.951939,31.341006 10.488989,29.942447 9.0226896,28.547471 7.4948229,27.094386 5.9665374,25.641302 4.4395083,24.187422 2.4965878,22.337138 1.1580292,20.148955 0.47157807,17.619291 0.0535926,16.07825 -0.10472254,14.502583 0.07034553,12.910996 0.42592635,9.6824513 1.7406121,6.8622503 4.0659181,4.4762631 5.373065,3.134618 6.9038635,2.086296 8.6440735,1.3102034 10.329418,0.55918464 12.119049,0.20297806 13.958101,0.03502366 c 1.061717,-0.09671307 2.1138,0.02188979 3.160858,0.17034237 1.528705,0.21730592 2.982021,0.6682356 4.35953,1.33129727 1.489335,0.7167911 2.833338,1.6274064 3.98929,2.7792074 1.263171,1.2596579 2.264996,2.6840901 2.981602,4.2876129 0.490023,1.0968775 0.813355,2.2335544 1.003919,3.4152054 0.117689,0.730721 0.141562,1.458656 0.157896,2.190571 0.05612,2.068784 -0.453166,4.019761 -1.359081,5.886761 z',
                fillColor: theme.colors.primary[600],
                fillOpacity: 10,
                scale: 0.85,
                strokeColor: 'transparent',
              },
              anchor: {
                x: 15.5,
                y: 44,
              },
            },
          },
        ]}
      />
    </GenericBox>
  );
};

export default MapLocation;
