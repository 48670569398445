import {FC} from 'react';
import {Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from '../GenericBox';

type DataItem = {
  value?: number;
  units?: string;
};

export type Props = GenericBoxProps & {
  data: DataItem;
  isNotBoxed?: boolean;
  smallUnits?: boolean;
};

const ComponentValue: FC<Props> = ({isNotBoxed = false, data, smallUnits = false, ...genericBoxProps}) => {
  const {t} = useTranslation();

  if ((!data.value && data.value !== 0) || !data.units) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  const render = (
    <View flexDirection="row" alignItems="center">
      <Text fontSize="3xl">
        {data.value} {smallUnits ? '' : data.units}
      </Text>
      {smallUnits ? <Text fontSize="sm">{data.units}</Text> : ''}
    </View>
  );

  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};

export default ComponentValue;
