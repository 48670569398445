import {FC} from 'react';
import {ScrollView, Stack} from 'native-base';
import {ImageGalleryThumbnail} from './ImageGalleryThumbnail';

type Props = {
  images: string[];
  isLayoutVertical: boolean;
  handleThumbnailClick: (image: string) => void;
};

export const ImageGalleryThumbnails: FC<Props> = ({images, isLayoutVertical, handleThumbnailClick}) => (
  <ScrollView horizontal={!isLayoutVertical} height="100%">
    <Stack direction={['row', 'row', 'column']} space={3} height="100%" overflow="auto">
      {images.map((image, index) => (
        <ImageGalleryThumbnail image={image} key={index} handleClick={handleThumbnailClick} />
      ))}
    </Stack>
  </ScrollView>
);
