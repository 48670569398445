import {CookiesConsent} from '@bri/shared-components';
import {PageLayout} from '../components/PageLayout';

const CookiesConsentPage = () => (
  <PageLayout>
    <CookiesConsent onRemoveAllCookies={() => window.location.reload()} titleFontSize="2xl" />
  </PageLayout>
);

export default CookiesConsentPage;
