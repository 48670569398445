import {Image} from 'react-native';

export type ImageResource = {
  uri: string;
};

const getResource = (required: number | string): ImageResource => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required);
  }
  return {uri: required};
};

const getResourceUri = (required: number | string): string => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required).uri;
  }
  return required;
};

/* eslint-disable global-require */
const Images = {
  background: require('../images/oocl-hong-kong.jpg'),
  productPassportLogo: require('../images/product-passport-logo.png'),
  adalmoLogo: require('../images/adalmo-logo.png'),
  celsaLogo: require('../images/celsa-logo.png'),
  imageTemp: require('../images/tuberia-industrial.jpg'),
};
/* eslint-enable global-require */

export type ImageDimensions = {
  width: number;
  height: number;
};

const getImageDimensions = (imageUri: string): Promise<ImageDimensions> =>
  new Promise((resolve, reject) => {
    Image.getSize(
      imageUri,
      (width, height) => resolve({width, height}),
      () => reject()
    );
  });

export {Images, getResource, getResourceUri, getImageDimensions};
