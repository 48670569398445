import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AddIcon, Button} from 'native-base';
import {PageLayout} from '../../components/PageLayout';
import {ListPassports, ListPassportsRef} from '../../components/ListPassports';
import {ModalAddUrl} from '../../components/modals/ModalAddUrl';

const PassportListPage: FC = () => {
  const {t} = useTranslation();
  const childRef = useRef<ListPassportsRef>(null);
  const [showModalAddUrl, setShowModalAddUrl] = useState(false);

  return (
    <PageLayout
      title={t('Pasaportes de producto')}
      button={
        <Button onPress={() => setShowModalAddUrl(true)} my={5} px={5} style={{width: 'auto', alignSelf: 'flex-end'}} leftIcon={<AddIcon />}>
          {t('Add Remote Passport')}
        </Button>
      }>
      <ModalAddUrl
        showModal={showModalAddUrl}
        onClose={() => setShowModalAddUrl(false)}
        onSuccess={() => {
          childRef.current?.fetchData();
          setShowModalAddUrl(false);
        }}
      />
      <ListPassports ref={childRef} />
    </PageLayout>
  );
};
export default PassportListPage;
