import {FC, useEffect, useState} from 'react';
import {HStack, Icon, VStack, View, useBreakpointValue, Text, Image} from 'native-base';
import {useTranslation} from 'react-i18next';
import {IProductPassport} from '@bri/circularpass-adalmo-core';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {GenericModal, Props as BaseProps} from './GenericModal';
import TableBox, {RowData} from './TableBox';
import AdalmoIcon from '../config/adalmo-font.js';
import {getIconForProperty} from '../utils/icons';
import {Mapping} from '../utils/fieldMapping';
import {useProductPassportsService} from '../services/ProductPassportsService';
import {KeyValue, ParsedProduct} from '../types/types';
import {Images, getResource} from '../utils/imagesUtils';

type Props = Pick<BaseProps, 'isOpen' | 'onClose'> & {
  id?: IProductPassport['@id'];
};

const SummarySheetModal: FC<Props> = ({id, isOpen, onClose}) => {
  const {t} = useTranslation();
  const sharedToast = useSharedToast();

  const icon = (property: string) => <Icon as={AdalmoIcon} name={getIconForProperty(property)} color="primary.600" size="md" alignSelf="center" />;
  const images: string[] = [];
  const isModalVertical: boolean = useBreakpointValue({base: true, lg: false});

  const productPassportService = useProductPassportsService();
  const [product, setProduct] = useState<ParsedProduct>();

  useEffect(() => {
    if (!id) {
      return;
    }
    productPassportService
      .getByIdPassport({idPassport: id})
      .response(response => setProduct(Mapping(response)))
      // .response(response => console.log(response))
      .error(() => {
        sharedToast({
          title: t('Algo ha ido mal'),
          description: t('No se ha podido obtener la información de la ficha resumen seleccionada'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
          isClosable: true,
        });

        onClose();
      });
  }, [id]);

  if (!id || !product) {
    return null;
  }

  const data = {
    scrap: {
      name: product?.name || 'No name',
      images: product?.image ? [`${product?.image}`] || [getResource(Images.imageTemp).uri] : [getResource(Images.imageTemp).uri],
      properties: product?.properties,
      circularityIndex: {
        recycledMaterialUsagePercent: product?.circularityIndex?.recycledMaterialUsagePercent || 0,
        wasteValorizationPercent: product?.circularityIndex?.wasteValorizationPercent || 0,
        carbonFootprint: {
          value: +(product?.circularityIndex?.carbonFootprint?.value?.toFixed(2) || 0) || 0,
          units: product?.circularityIndex?.carbonFootprint?.units || 'CO2',
        },
        co2Reduction: {
          value: +(product?.circularityIndex?.co2Reduction?.value?.toFixed(2) || 0) || 0,
          units: product?.circularityIndex?.co2Reduction?.units || 'Co2',
          breakdown:
            product?.circularityIndex?.co2Reduction?.breakdown?.map(item => {
              const key = Object.keys(item.data)[0];
              const value = item.data[key as any];
              return {title: key, value: value.toFixed(2), percent: item.percent};
            }) || [],
        },
      },
    },
  };

  if (product.images) {
    data.scrap.images.concat(product.images);
  }

  const passportProduct = data[id as keyof typeof data] || data.scrap;

  const properties = () => {
    const result: {[key: keyof KeyValue]: RowData} = {};

    const carbonFootprintLabel = t('Huella de carbono')!;
    result[carbonFootprintLabel] = {
      label: t(carbonFootprintLabel),
      value: `${passportProduct?.circularityIndex?.carbonFootprint.value} ${passportProduct?.circularityIndex?.carbonFootprint.units}`,
      icon: icon(carbonFootprintLabel),
    };

    const co2SavingsLabel = t('Ahorro CO2')!;
    result[co2SavingsLabel] = {
      label: t(co2SavingsLabel),
      value: `${passportProduct?.circularityIndex?.co2Reduction.value} ${passportProduct?.circularityIndex?.co2Reduction.units}`,
      icon: icon(co2SavingsLabel),
    };

    if (!(passportProduct?.properties || []).length) {
      return Object.values(result);
    }

    for (const property of passportProduct?.properties || []) {
      for (const [label, value] of Object.entries(property)) {
        result[label] = {label, value, icon: icon(label)};
      }
    }

    if (!product?.images) {
      images.push(product?.image || '');
    }

    return Object.values(result);
  };

  return (
    <GenericModal
      title={
        <HStack space="2xl">
          <View>
            <Image source={{uri: images[0] || ''}} borderRadius={10} w={100} h={100} resizeMode="cover" />
          </View>
          <VStack>
            <Text color="coolGray.700" fontWeight="bold">
              {t('Ficha resumen')}
            </Text>
            <Text fontSize="2xl" color="coolGray.700" fontWeight="bold">
              {passportProduct?.name}
            </Text>
          </VStack>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      heightPercent={isModalVertical ? 0.9 : 0.85}
      widthPercent={isModalVertical ? 0.95 : 0.75}
      padding={isModalVertical ? {sides: '30px', top: '20px', bottom: '10px'} : {sides: '40px', top: '40px', bottom: '5px'}}
      minHeight="0px">
      <View py={5} pb={10}>
        <TableBox isNotBoxed data={properties()} />
      </View>
    </GenericModal>
  );
};

export default SummarySheetModal;
