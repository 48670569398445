import {Image, View} from 'native-base';
import {FC, Key} from 'react';
import {Pressable} from 'react-native';

type Props = {
  image: string;
  key: Key;
  handleClick: (image: string) => void;
};

export const ImageGalleryThumbnail: FC<Props> = ({image, key, handleClick}) => (
  <View>
    <Pressable key={key} onPress={() => handleClick(image)}>
      <Image source={{uri: image}} width={46} height={60} borderRadius={10} />
    </Pressable>
  </View>
);
