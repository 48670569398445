import {HStack, Text, View, VStack} from 'native-base';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as BoxProps} from './GenericBox';

export type RowData = {
  label: string;
  value: string;
  icon?: JSX.Element;
};

type Props = BoxProps & {
  data: RowData[];
  footer?: JSX.Element;
  isNotBoxed?: boolean;
};

const TableBox: FC<Props> = ({isNotBoxed = false, data, footer, ...genericBoxProps}: Props) => {
  const {t} = useTranslation();

  if (!data || !data.length) {
    return (
      <GenericBox {...genericBoxProps}>
        <Text>{t(`No se ha encontrado ${genericBoxProps.title}`)}</Text>
      </GenericBox>
    );
  }

  const render = (
    <VStack mt={2}>
      <VStack>
        {data.map(({label, value, icon}, index) => (
          <HStack key={index} py={2} borderRadius={5} backgroundColor={index % 2 === 0 ? 'coolGray.100' : 'white'}>
            <View px={2} pr={2} alignSelf="center" flex={1 / 2}>
              <HStack space={icon ? 'sm' : undefined}>
                {icon || ''}
                <Text>{label}</Text>
              </HStack>
            </View>
            <View px={2} alignSelf="center" flex={1 / 2}>
              <Text fontWeight="bold" textAlign="right" numberOfLines={1}>
                {value}
              </Text>
            </View>
          </HStack>
        ))}
      </VStack>
      {footer ? (
        <View mt={6} alignItems="center">
          {footer}
        </View>
      ) : (
        ''
      )}
    </VStack>
  );

  return isNotBoxed ? render : <GenericBox {...genericBoxProps}>{render}</GenericBox>;
};

export default TableBox;
